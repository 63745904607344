import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './Actions.css';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import EyeIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/AddCircle';
import MinusIcon from '@mui/icons-material/RemoveCircle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { debounce } from "debounce";
import StatusTag from '../../Common/StatusTag';
import _ from 'lodash';
import PrintButton from '../../Common/PrintButton';
import OrderStats from '../../Common/OrderStats';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height:'5px'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#91BCB9',
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    padding:0,
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#008080',
    },
  }),
);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Client = new Api();
const moment = require('moment');

const categories = [
  "Drink ingredients",
  "Food Ingredients",
  "Packaging",
  "Merchandise",
  "Disposables",
  "Petcan stickers (350 ml)",
  // "Petcan stickers (475 ml)",
  "Snacks box Sticker"
];

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.75),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  }));
  
  const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
  }));
  
  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '12ch',
        '&:focus': {
          width: '20ch',
        },
      },
    },
  }));


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "100%",
    maxWidth:800,
    maxHeight:'80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY:'auto',
    p: 4,
  };

class Actions extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            tabValue:0,
            currentlyEditingOrder:{},
            loading:false,
            orderItemsArr:[],
            processedItemsObj:{},
            orderItemsObj:{},
            actionItemsArr:[],
            editingMaterialName:'',
            editMode:false,
            activeMatId:'',
            selectedItem:{},
            selectedQuantity:0,
            confirmedOrder:false,
            fieldErrors:{},
            showAddModal:false,
            deleteConfirmationShown:false,
            showOrderModal:false,
            browser : Utilities.getCurrentBrowser(),
        }
    }

    goBack = ()=> {
      this.setState({
        confirmedOrder:false
      })
    }

    handleTabChange = (event, newValue)=>{
      this.setState({
        loading:true,
        tabValue:newValue
      },()=>{
        this.loadActions();
      })
    }


    loadActions = ()=>{
        this.setState({
            loading:true
        },()=>{
          let statusArr = [];
          if(this.state.tabValue === 0) {
            statusArr = ["Order Placed","Acknowledged","Dispatched"];
          } else {
            statusArr = ["Delivered","Delivered Partially"];
          }
          Client.searchActions('',{actionType:['inventoryOrder'],status:statusArr}).then((loadedActions)=>{
              this.setState({
                  actionItemsArr:loadedActions,
                  loading:false,
              });
          })
        })
    }

    // handlePlaceOrder = ()=> {
    //   const {orderItemsObj,actionItemsArr} = this.state;
    //   let action = {};
    //   action.actionType = "inventoryOrder";
    //   action.branch = "Galleria";//TODO: Change Based on Branch
    //   action.status = "Order Placed";
    //   action.data = {itemMap : {...orderItemsObj},items:[]};
    //   action.data.items = actionItemsArr.filter((i)=> { return !Utilities.isNullOrEmpty(orderItemsObj[i.id])});
    //   Client.createNewAction(action).then((res)=>{
    //     alert("Order Placed");
    //   });
    // }

    debouncedMaterials = debounce(this.loadActions,400);
    componentDidMount(){
      this.loadMaterials();
      this.loadActions();
    }

    loadMaterials = ()=>{
      this.setState({
          loading:true
      },()=>{
          Client.searchMaterials(this.state.searchTerm,{status:["Active"]}).then((data)=>{
              let oItems = [];        
              let catHash = {};        
              for(let item of data){
                if(Utilities.isNullOrEmpty(catHash[item.category])){
                  catHash[item.category] = [];
                }
                catHash[item.category].push({ id:item.id, name : item.name, unitSize:parseFloat(item.unitSize), unit:item.unit});
              }
              for(let c of categories){
                oItems.push({ category : c, items : catHash[c]||[]});
              }
              this.setState({
                  orderItemsArr:oItems,
                  loading:false,
                  materialsData:data
              });
          })
      })
  }

    showEditModal = (selectedItem,materialName, materialId, qty)=>{
        this.setState({
          selectedItem,
          activeMatId:materialId,
          editingMaterialName:materialName,
          showAddModal:true,
          selectedQuantity:qty,
          editMode:true
        });
    }

    showDeleteModal = (rowIndex,materialName, materialId)=>{
      this.setState({
        activeMatId:materialId,
        activeRowIndex:rowIndex,
        deleteConfirmationShown:true
      });
    }

    handleShowAddItem = (e)=>{
      this.resetModal(()=>{
        this.setState({
          showAddModal:true,
        });
      });
    }

    handleCloseModals = (e)=>{
      this.resetModal(()=>{
        this.setState({
          activeMatId:undefined,
          activeRowIndex:undefined,
          showAddModal:false,
          deleteConfirmationShown:false
        });
      });
    }

    handleFieldChange = (fieldName, e)=>{
      let fieldErrorsCopy = {...this.state.fieldErrors};
      delete fieldErrorsCopy[fieldName];
      console.log("field name", fieldName);
      console.log("field errors", fieldErrorsCopy);
      if(fieldName === 'selectedItem'){
        let selectedItem = e;
        this.setState({
          selectedItem,
          fieldErrors:fieldErrorsCopy
        });
      } else {
        console.log("field errors state being set", fieldErrorsCopy);
        this.setState({
          [`${fieldName}`]:e.target.value,
          fieldErrors:fieldErrorsCopy
        },()=>{
  
        });
      }
    }

    resetModal = (cb)=>{
      this.setState({
        editMode:false,
        fieldErrors:{},
        selectedItem:{},
        selectedQuantity:0
      },()=>{
        cb();
      });
    }

    increaseItemQuantity = (itemId, incrementUnit)=>{
      const {orderItemsObj} = this.state;
      let orderItemsObjCopy = _.cloneDeep(orderItemsObj);
      if(orderItemsObjCopy[itemId]){
        orderItemsObjCopy[itemId].qty += incrementUnit;
      } else {
        orderItemsObjCopy[itemId] = {qty:incrementUnit}
      }
      this.setState({
        orderItemsObj:orderItemsObjCopy
      },()=>{
        console.log(this.state.actionItemsArr);
      })
    }

    decreaseItemQuantity = (itemId, incrementUnit)=>{
      const {orderItemsObj} = this.state;
      let orderItemsObjCopy = _.cloneDeep(orderItemsObj);
      if(orderItemsObjCopy[itemId]){
        orderItemsObjCopy[itemId].qty -= incrementUnit;
      } else {
        orderItemsObjCopy[itemId] = {qty:0}
      }
      if(orderItemsObjCopy[itemId].qty <= 0){
        //delete orderItemsObjCopy[itemId];
        orderItemsObjCopy[itemId].qty = 0;
      }

      this.setState({
        orderItemsObj:orderItemsObjCopy
      })
    }

    deleteItem = (toDeleteIndex)=>{
      this.setState({
        deleteConfirmationShown:false
      },()=>{
        let orderItemsObjCopy = {...this.state.orderItemsObj};
        let actionItemsArrCopy = this.state.actionItemsArr.slice();
        actionItemsArrCopy.splice(toDeleteIndex,1);
        if(orderItemsObjCopy[this.state.activeMatId]){
          delete orderItemsObjCopy[this.state.activeMatId];
        }
        this.setState({
          orderItemsObj:orderItemsObjCopy,
          materialsData:this.state.materialsData,
          actionItemsArr: actionItemsArrCopy
        })
      })
    }

    validateFields = ()=>{
      let errors = {};
      if(Utilities.isNullOrEmpty(this.state.selectedItem)){
        errors['selectedItem'] = 'Item is required.';
      }
      if(Utilities.isNullOrEmpty(this.state.selectedQuantity) || this.state.selectedQuantity <= 0){
        errors['selectedQuantity'] = 'Quantity cannot be zero.';
      }
      if(!(Utilities.isNullOrEmpty(errors))){
        console.log(errors);
        this.setState({
          fieldErrors:errors
        });
        return false;
      }
      return true;
    }

    viewOrder = (order)=> {
      let orderItemsObjCopy = _.cloneDeep(order.data.itemMap);
      let orderItems = _.cloneDeep(order.data.items);
      let processedItemsObj = {};
      if(["Dispatched","Delivered","Delivered Partially"].includes(order.status)){
        processedItemsObj = _.cloneDeep(order.processedData.itemMap);
      }
      this.setState({
        orderItemsObj:orderItemsObjCopy,
        processedItemsObj,
        orderItemsArr:orderItems,
        currentlyEditingOrder:order,
        confirmedOrder:true,
        editingOrder:true,
        showOrderModal:true
      });
  }
    
    confirmOrder = ()=> {
      if(this.state.confirmedOrder){
        // this.placeOrder();
      } else {
        this.setState({
          confirmedOrder:true
        });
      }
    }

    handleCloseModals = (e)=>{
        e.preventDefault();
        this.setState({
          orderItemsObj:{},
          confirmedOrder:false,
          showOrderModal:false,
          editingOrder:false
        },()=>{
        });
    }

    acceptOrder = ()=> {
      const {currentlyEditingOrder} = this.state;
          let action = {};
          action.status = "Acknowledged";
         this.setState({
          loading:true,
          showOrderModal:false,
        },()=>{
          Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
          this.loadActions();
          });
        })
    }

    processOrder = ()=> {
      const {orderItemsObj,currentlyEditingOrder} = this.state;
      let action = {};
      action.status = "Dispatched";
      action.data = {itemMap : _.cloneDeep(orderItemsObj),items:[]};
      let items = [];
      let orderItemsArrCopy = _.cloneDeep(this.state.orderItemsArr);
      for(let cat of orderItemsArrCopy){
        if(cat && cat.items.length > 0){
          let temp = [];
          let newObj = {};
          for(let item of cat.items){
            if(orderItemsObj[item.id]){
              temp.push(item);
            }
          }
          newObj = {category:cat.category, items: temp};
          items.push(newObj);
        }
      }
      action.data.items = items;
      action.processedData = _.cloneDeep(action.data);
      this.setState({
        loading:true,
        showOrderModal:false,
      },()=>{
        Client.updateAction(currentlyEditingOrder.id,action).then((res)=>{
        this.loadActions();
        });
      })

    }
    
    renderActionsTable(){
      const {actionItemsArr} = this.state;
      return  <TableContainer sx={{margin:0, maxHeight:'60vh', maxWidth:'94vw'}}component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Order ID</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Order Time</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Branch</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">Status</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }} align="left">View</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.loading ? <TableRow
              key={'loader'}
            >
              <TableCell component="th" scope="row" colSpan={5} sx={{textAlign:'center'}}>
              <Loader/>
              </TableCell>
              </TableRow> : <>{actionItemsArr.map((row,index) => (
            <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="subtitle1" sx={{textAlign:'left'}}>
              {row.id}
              </Typography>
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="subtitle1" sx={{textAlign:'left'}}>
              {moment(row.createdAt).format("ddd DD-MMM-YYYY, hh:mm A")}
              </Typography>
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="subtitle1" sx={{textAlign:'left'}}>
              <StatusTag status={row.branch}/>
              </Typography>
              </TableCell>
              <TableCell align="left" component="th" scope="row">
                <Typography variant="subtitle1" component="subtitle1" sx={{textAlign:'left'}}>
              <StatusTag status={row.status}/>
              </Typography>
              </TableCell>
              <TableCell align="left">
              <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
              <Button color="success" variant="contained" children={<EyeIcon />} onClick={()=>this.viewOrder(row)}/>
              </Stack>
                </TableCell>
            </TableRow>
          ))}</>}
          
        </TableBody>
      </Table>
    </TableContainer>
    }
      
    render(){
        const {orderItemsObj, processedItemsObj, orderItemsArr,tabValue } = this.state;

        return  <div className='inventoryOrderContainer'><ReportsHeader/>
        <Box sx={{padding:0}}>
        <Modal
        open={this.state.deleteConfirmationShown}
        onClose={this.handleCloseModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography sx={{mb:5, textAlign:'center'}}>Are you sure you want to delete <b>{this.state.activeMaterialName}</b>?</Typography>
          <Stack spacing={2} direction="row" justifyContent={'center'}>
                  <Button 
                  color="success" 
                  variant="contained" 
                  startIcon={<EditIcon/>}
                  onClick={()=>this.deleteItem(this.state.activeRowIndex)}
                  >Yes, Delete</Button>
                  <Button 
                  color="error" 
                  variant="contained" 
                  startIcon={<DeleteIcon/>}
                  onClick={this.handleCloseModals}
                  >No, Don't</Button>
          </Stack>
        </Box>
       </Modal>  
        <Modal
        open={this.state.showOrderModal}
        onClose={this.handleCloseModals}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
        <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <PrintButton contentId={"orderTableFinal"}/>
        <OrderStats orderData={this.state.currentlyEditingOrder}/>
        {this.state.confirmedOrder ? 
        <TableContainer id="orderTableFinal" sx={{maxHeight:'60vh'}} component={Paper}>
          <Typography className="onlyForPrint">{this.state.currentlyEditingOrder.branch} Order</Typography>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>
           {orderItemsArr.map((cat,_) => { 
            return  cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).length ? <TableRow><TableCell>
                      <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{cat.category}</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">
                {(this.state.confirmedOrder && ["Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ? "Amount Sent": "Amount to Order"}
              </TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{(this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ? "Amount Received" : "Measurement"}</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint">Done</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).map((row,index) => (
              <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              <TableCell align="left" component="th" scope="row" sx={{width:'30%'}}>
                <Typography variant="subtitle1" component="subtitle1" sx={{textAlign:'left'}}>
              {row.name}
              </Typography>
              </TableCell>
              <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
                {(this.state.confirmedOrder && ["Dispatched","Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                (processedItemsObj[row.id] ? processedItemsObj[row.id].qty : 0):(orderItemsObj[row.id] ? orderItemsObj[row.id].qty : 0)
                } units {(this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                `${processedItemsObj[row.id] ? (parseFloat(processedItemsObj[row.id].qty) * parseFloat(row.unitSize)) : 0} ${row.unit}` : ''}
                </Typography>
                </Stack>
              </TableCell>
              <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
                {(this.state.confirmedOrder && ["Delivered","Delivered Partially"].includes(this.state.currentlyEditingOrder.status)) ?
                (orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty)} units` : '') : ''
                } {(this.state.confirmedOrder && ["Dispatched"].includes(this.state.currentlyEditingOrder.status)) ?
                (processedItemsObj[row.id] ? `${parseFloat(processedItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''):(orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : '')
                }
                </Typography>
                </Stack>
              </TableCell>
              {/* <TableCell align="left">
                <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                <Typography variant="subtitle1" component="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
                {orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''}
                </Typography>
                </Stack>
              </TableCell> */}
                <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint"></TableCell>
              </TableRow>
              ))}
              </TableBody>
            </Table>      
            </TableCell>
          </TableRow> : '';}
          )}
          </TableBody></Table>
          </TableContainer> : <TableContainer id="orderTableFinal" sx={{maxHeight:'60vh'}} component={Paper}>
          <Typography className="onlyForPrint">{this.state.currentlyEditingOrder.branch} Order</Typography>
          <Table aria-label="simple table" stickyHeader>
            <TableBody>
           {orderItemsArr.map((cat,_) => { 
            return  cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).length ? <TableRow><TableCell>
                      <Table aria-label="simple table">
            <TableHead>
              <TableRow>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">{cat.category}</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Amount to Order</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left">Edit Amount</TableCell>
              <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint">Done</TableCell>
              </TableRow>
            </TableHead>
              <TableBody>
              {cat.items.filter((o)=>orderItemsObj[o.id] ? true : false).map((row,index) => (
              <TableRow
              key={`${row}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
              <TableCell align="left" component="th" scope="row" sx={{width:'30%'}}>
                <Typography variant="subtitle1" component="subtitle1" sx={{textAlign:'left'}}>
              {row.name}
              </Typography>
              </TableCell>
              <TableCell align="left">
              <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
              <Typography variant="subtitle1" component="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
              {orderItemsObj[row.id] ? orderItemsObj[row.id].qty : 0} units {orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''}
              </Typography>
              </Stack>
                </TableCell>
                <TableCell align="left">
                  <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%'>
                  <Button color="error" variant="contained" children={<MinusIcon />} onClick={()=>{this.decreaseItemQuantity(row.id,row.incrementUnit)}}/>
                  <Typography variant="subtitle1" component="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
                  {orderItemsObj[row.id] ? orderItemsObj[row.id].qty : 0}
                  </Typography>
                  <Button color="success" variant="contained" children={<AddIcon />} onClick={()=>{this.increaseItemQuantity(row.id,row.incrementUnit)}}/>
                  <Typography variant="subtitle1" component="subtitle1" sx={{minWidth:50,textAlign:'center'}}>
                  {orderItemsObj[row.id] ? `${parseFloat(orderItemsObj[row.id].qty * row.unitSize)} ${row.unit}` : ''}
                  </Typography>
                  </Stack>
                </TableCell>
                <TableCell sx={{ background:"#47908a", fontWeight: 'bold', color:"#FFF"}} align="left" className="onlyForPrint"></TableCell>
              </TableRow>
              ))}
              </TableBody>
            </Table>      
            </TableCell>
          </TableRow> : '';}
          )}
          </TableBody></Table>
          </TableContainer>}
      <Stack spacing={1} direction={{ xs: 'row', sm: 'row' }} width='100%' justifyContent="flex-end" sx={{mt:2}}>
      {(this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.goBack}>
        {'Edit'}
      </Button> : ''}
      {(!this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.confirmOrder}>
        {'Confirm'}
      </Button> : ''}
    {(this.state.confirmedOrder && ["Acknowledged"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.processOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
      {'Dispatch Order'}
    </Button> : ''}
    {(this.state.confirmedOrder && ["Order Placed"].includes(this.state.currentlyEditingOrder.status))? <Button color="success" variant="contained" onClick={this.acceptOrder} disabled={Utilities.isNullOrEmpty(this.state.orderItemsObj)}>
      {'Acknowledge Order'}
    </Button> : ''}
      <Button color="error" variant="contained" onClick={this.handleCloseModals}>
        {'Close'}
      </Button>
      </Stack>
      </Box>
        </Box>
        </Modal>
        <Box
          sx={{ flexGrow: 1, bgcolor: '#008080', display: 'flex',padding:0}}
        >
          <StyledTabs
          value={tabValue}
          onChange={this.handleTabChange}
          aria-label="styled tabs example"
          >
            <StyledTab label="Pending" />
            <StyledTab label="Done" />
          </StyledTabs>
      </Box>
        <TabPanel value={tabValue} index={0}>
      {this.renderActionsTable()}
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
      {this.renderActionsTable()}
      </TabPanel>
      </Box>
      </div>
    } 
}

export default Actions;