import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';
import { Box } from '@mui/material';
import StatusTag from './StatusTag';
import Chip from '@mui/material/Chip';
const moment = require('moment');

const OrderStats = ({ orderData }) => {
  return (
    <Box sx={{display:'flex',flexDirection:'row',border:'solid 2px rgba(53, 59, 72, 0.2)',borderRadius:2,mb:1, justifyContent:'space-around', alignItems:'center',padding:1}}>
      <Box sx={{background:"#353b48",fontSize:18, color:"#FFFFFF", padding:2,borderRadius:3}}>#{orderData.id}</Box>
      <Box sx={{background:"#353b48",fontSize:18, color:"#FFFFFF", padding:2,borderRadius:3}}>{orderData.branch}</Box>
      <Box sx={{background:"#353b48",fontSize:18, color:"#FFFFFF", padding:2,borderRadius:3}}>{moment(orderData.createdAt).format("ddd DD-MMM-YYYY, hh:mm A")}</Box>
      <StatusTag status={orderData.status} fontSize={16}/>
      </Box>
  );
};

export default OrderStats;