import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import StatsTable from '../../Common/StatsTable/StatsTable';
import Totals from '../../Common/Totals/Totals';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './Reports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';

const Client = new Api();
const moment = require('moment');
class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            browser : Utilities.getCurrentBrowser(),
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD'),
            cash : 0.0,
            cashCount : 0,
            revenueTotals : [],
            customerName : '',
            money : 0.0,
            data : null,
            stats : null,
            orderCount : 0,
            snackCount : 0,
            drinksCount : 0,
            orders:null,
            addOnItems : [],
            orderItems : [],
            unknownOrderCount : 0,
            phoneNum:props.match.params.phonenum
        }
        this.timer = null;
    }

    loadOrders = async ()=>{
        const {startDate, endDate } = this.state;
        let fbChat = document.querySelector('.fb_dialog');
        if(fbChat){ fbChat.remove();}
        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');
        this.setState({
            loading:true
        },()=>{
            Client.getLimetrayRevenueData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((revenueTotals)=>{
                Client.getLimetrayPaymentsData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((paymentTotals)=>{
                    let cash = 0.0;
                    let cashCount = 0;
                    for(let item of paymentTotals){
                        if(item["Payment Mode"] === "CASH"){
                            cash =  parseFloat(item["Revenue"]);
                            cashCount = parseInt(item["Payment Count"]);
                            break;
                        }
                    }
                    Client.getLimetrayItemData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((orderItems)=>{
                    Client.getShopifyOrderData(moment(startDateString).toISOString(), moment(endDateString).add(1,'days').toISOString()).then((shopifyOrderData)=>{
                    Client.getLimetrayAddOnData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((addOnItems)=>{
                    
                    let shopifyOrderItems = shopifyOrderData.items;
                    let shopifyOrderCount = shopifyOrderData.orderCount;
                    let shopifyRevenue = 0.0;
                    for(let item of shopifyOrderItems){
                        shopifyRevenue += parseFloat(item.price);
                    }

                    revenueTotals.push({
                        "Source" : "Shopify",
                        "Revenue" : shopifyRevenue,
                        "Order Count" : shopifyOrderCount
                    });
    
                    this.setState({
                        addOnItems,
                        orderItems,
                        shopifyOrderItems,
                        shopifyOrderCount,
                        shopifyRevenue,
                        cash,
                        cashCount,
                        revenueTotals,
                        loading:false
                    },()=>{
                        this.filterOrders();
                    });
                    }).catch((err)=>{
                        console.log('LOAD Addons ERROR =>', err);
                    });
                    }).catch((err)=>{
                        console.log('SHOPIFY LOAD ERROR =>', err);
                    });
                    }).catch((err)=>{
                        console.log('LOAD ORDERS ERROR =>', err);
                    });
                    Client.getLimetrayCustomerData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((customerOrderData)=>{
                        let unknownOrderCount = 0;
                        for(let o of customerOrderData){
                            if(Utilities.isNullOrEmpty(o.customermobile)){
                                unknownOrderCount++;
                            }
                        }
                        this.setState({
                            unknownOrderCount
                        });
                    })
                }).catch((err)=>{
                    console.log('LOAD PAYMENTS ERROR =>', err);
                });;
            }).catch((err)=>{
                console.log('LOAD ORDERS ERROR =>', err);
            });
        })
        // Client.getAllOrders('COMPLETED').then((orders)=>{
        //     this.setState({
        //         orders
        //     },()=>{
        //         this.filterOrders(orders);
        //     })
        // });
    }

    filterOrders = () =>{
        const { addOnItems, orderItems, shopifyOrderItems} = this.state;
        let overallDrinksCounter = {};
        let overallSnacksCounter = {};
        let overallComboCounter = {};
        let overallMerchCounter = {};

        let posPickupDrinksCounter = {};
        let posPickupSnacksCounter = {};
        let posDineInDrinksCounter = {};
        let posDineInSnacksCounter = {};
        let posDineInComboCounter = {};
        let posPickupComboCounter =  {};

        let zomatoDeliveryDrinksCounter = {};
        let zomatoDeliverySnacksCounter = {};
        let zomatoDeliveryComboCounter = {};

        let swiggyDeliveryDrinksCounter = {};
        let swiggyDeliverySnacksCounter = {};
        let swiggyDeliveryComboCounter = {};

        let dotpePickupDrinksCounter = {};
        let dotpePickupSnacksCounter = {};
        let dotpePickupComboCounter = {};
        let dotpeDeliveryDrinksCounter = {};
        let dotpeDeliverySnacksCounter = {};
        let dotpeDeliveryComboCounter = {};

        let arr = [];
        let money = 0.0;
        let drinksCount = 0;
        let snackCount = 0;
        let merchCount = 0;
        for(const item of orderItems){
            let orderType = item.Service.toLowerCase();
            let source = item.Source.toLowerCase();
            let isDelivery = orderType === 'delivery';
            let isDineIn = orderType === 'dinein';
            money += parseFloat(item["Revenue"]);
            let itemName = item["Item Name"];
            if(itemName.toLowerCase().includes("croffle")){
                if(itemName.trim() === "Bubble Tea + Croffle Combo" || itemName.trim() === "1 Bubble Tea + Original Croffle"){
                    itemName = "1 Bubble Tea + 1 Croffle";
                }
                itemName = itemName.replace("(-10%)","").replace("Croffle - Original","Original Croffle").trim();
                if(itemName.trim() === "Corn and Cheese Croffle"){
                    itemName = "Corn Cheese Croffle";
                }
            } else if(itemName.toLowerCase().includes("sandwich")){
                if(itemName.trim() === "Sandwich Veg"){
                    itemName = "Veg Sandwich";
                }
                if(itemName.trim() === "Sandwich Non Veg" || itemName.trim() === "Sandwich Chicken"){
                    itemName = "Non - Veg Sandwich";
                }
            } else {
                itemName = itemName.replace("Bubble Tea", "").replace("(-10%)","").replace("[No Bubbles]","").replace("No Bubbles","").replace("[New]","").trim();
            }
            if(Utilities.isCombo(itemName)){
                if(Utilities.isNullOrEmpty(overallComboCounter[itemName])){
                    overallComboCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallComboCounter[itemName].count = overallComboCounter[itemName].count + parseInt(item["Item Count"]);
                if(isDineIn){
                    posDineInComboCounter[itemName] = (posDineInComboCounter[itemName] || 0) + parseInt(item["Item Count"]);
                   overallComboCounter[itemName].dineIn = overallComboCounter[itemName].dineIn + parseInt(item["Item Count"]);
                } else if(isDelivery){
                    if(source === "zomato"){
                        zomatoDeliveryComboCounter[item["Item Name"]] = (zomatoDeliveryComboCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                        } else if(source === "swiggy"){
                        swiggyDeliveryComboCounter[item["Item Name"]] = (swiggyDeliveryComboCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                       }
                    else if(source === "dotpe"){
                        dotpeDeliveryComboCounter[item["Item Name"]] = (dotpeDeliveryComboCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                     }
                    overallComboCounter[itemName].delivery = overallComboCounter[itemName].delivery + parseInt(item["Item Count"]);
                } else {
                    if(source === "pos"){
                        posPickupComboCounter[item["Item Name"]] = (posPickupComboCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                        } else if(source === "dotpe"){
                        dotpePickupComboCounter[item["Item Name"]] = (dotpePickupComboCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                       }
                    overallComboCounter[itemName].takeaway = overallComboCounter[itemName].takeaway + parseInt(item["Item Count"]);
                }
            } else if(Utilities.isSnack(itemName)){
                snackCount += parseInt(item["Item Count"]);
                if(Utilities.isNullOrEmpty(overallSnacksCounter[itemName])){
                    overallSnacksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallSnacksCounter[itemName].count = overallSnacksCounter[itemName].count + parseInt(item["Item Count"]);
                if(isDineIn){
                    posDineInSnacksCounter[itemName] = (posDineInSnacksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    overallSnacksCounter[itemName].dineIn = overallSnacksCounter[itemName].dineIn + parseInt(item["Item Count"]);
                } else if(isDelivery){
                    if(source === "zomato"){
                        zomatoDeliverySnacksCounter[item["Item Name"]] = (zomatoDeliverySnacksCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                    } else if(source === "swiggy"){
                        swiggyDeliverySnacksCounter[item["Item Name"]] = (swiggyDeliverySnacksCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                    }
                    else if(source === "dotpe"){
                        dotpeDeliverySnacksCounter[item["Item Name"]] = (dotpeDeliverySnacksCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                    }
                    overallSnacksCounter[itemName].delivery = overallSnacksCounter[itemName].delivery + parseInt(item["Item Count"]);
                } else {
                    if(source === "pos"){
                        posPickupSnacksCounter[item["Item Name"]] = (posPickupSnacksCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                    } else if(source === "dotpe"){
                        dotpePickupSnacksCounter[item["Item Name"]] = (dotpePickupSnacksCounter[item["Item Name"]] || 0) + parseInt(item["Item Count"]);
                    }
                    overallSnacksCounter[itemName].takeaway = overallSnacksCounter[itemName].takeaway + parseInt(item["Item Count"]);
                }
            } else {
                drinksCount += parseInt(item["Item Count"]);
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallDrinksCounter[itemName].count = overallDrinksCounter[itemName].count + parseInt(item["Item Count"]);
                if(isDineIn){
                    posDineInDrinksCounter[itemName] = (posDineInDrinksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    overallDrinksCounter[itemName].dineIn = overallDrinksCounter[itemName].dineIn + parseInt(item["Item Count"]);
                } else if(isDelivery){
                    if(source === "zomato"){
                        zomatoDeliveryDrinksCounter[itemName] = (zomatoDeliveryDrinksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    } else if(source === "swiggy"){
                        swiggyDeliveryDrinksCounter[itemName] = (swiggyDeliveryDrinksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    }
                    else if(source === "dotpe"){
                        dotpeDeliveryDrinksCounter[itemName] = (dotpeDeliveryDrinksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    }
                    overallDrinksCounter[itemName].delivery = overallDrinksCounter[itemName].delivery + parseInt(item["Item Count"]);
                } else {
                    if(source === "pos"){
                        posPickupDrinksCounter[itemName] = (posPickupDrinksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    }else if(source === "dotpe"){
                        dotpePickupDrinksCounter[itemName] = (dotpePickupDrinksCounter[itemName] || 0) + parseInt(item["Item Count"]);
                    }
                    overallDrinksCounter[itemName].takeaway = overallDrinksCounter[itemName].takeaway + parseInt(item["Item Count"]);
                }
            }
        }
        for(const item of shopifyOrderItems){
            money += parseFloat(item["price"]);
            let itemName = item["name"];
            if(itemName.toLowerCase().includes("croffle")){
                if(itemName.trim() === "Bubble Tea + Croffle Combo" || itemName.trim() === "1 Bubble Tea + Original Croffle"){
                    itemName = "1 Bubble Tea + 1 Croffle";
                }
                itemName = itemName.replace("(-10%)","").replace("Croffle - Original","Original Croffle").trim();
                if(itemName.trim() === "Corn and Cheese Croffle"){
                    itemName = "Corn Cheese Croffle";
                }
            } else if(itemName.toLowerCase().includes("sandwich")){
                if(itemName.trim() === "Sandwich Veg"){
                    itemName = "Veg Sandwich";
                }
                if(itemName.trim() === "Sandwich Non Veg" || itemName.trim() === "Sandwich Chicken"){
                    itemName = "Non - Veg Sandwich";
                }
            } else {
                itemName = itemName.replace("[Pre-Order]", "").replace("Bubble Tea", "").replace("(-10%)","").replace("[No Bubbles]","").replace("No Bubbles","").replace("[New]","").trim();
            }

            if(Utilities.isCombo(itemName)){
                snackCount += parseInt(item["count"]);
                drinksCount += parseInt(item["count"]);

                if(Utilities.isNullOrEmpty(overallComboCounter[itemName])){
                    overallComboCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallComboCounter[itemName].count = overallComboCounter[itemName].count + parseInt(item["count"]);
            } else if(Utilities.isSnack(itemName)){
                snackCount += parseInt(item["count"]);
                if(Utilities.isNullOrEmpty(overallSnacksCounter[itemName])){
                    overallSnacksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallSnacksCounter[itemName].count = overallSnacksCounter[itemName].count + parseInt(item["count"]);
            } else if(Utilities.isMerch(itemName)){
                merchCount += parseInt(item["count"]);
                if(Utilities.isNullOrEmpty(overallMerchCounter[itemName])){
                    overallMerchCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallMerchCounter[itemName].count = overallMerchCounter[itemName].count + parseInt(item["count"]);
            } else {
                drinksCount += parseInt(item["count"]);
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallDrinksCounter[itemName].count = overallDrinksCounter[itemName].count + parseInt(item["count"]);
            }
        }
        for(const item of addOnItems){
            let itemName = item["Addon Name"];
            let orderType = item.Service.toLowerCase();
            let source = item.Source.toLowerCase();
            let isDelivery = orderType === 'delivery';
            let isDineIn = orderType === 'dinein';
            if(itemName.trim() === "Corn and Cheese Croffle"){
                itemName = "Corn Cheese Croffle";
            }
            if(itemName.trim() === "Peachy  Oolong"){
                itemName = "Peachy Oolong";
            }
            if(Utilities.isSnack(itemName)){
                if(Utilities.isNullOrEmpty(overallSnacksCounter[itemName])){
                    overallSnacksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallSnacksCounter[itemName].count = overallSnacksCounter[itemName].count + parseInt(item["Addon Count"]);
                if(isDineIn){
                    posDineInSnacksCounter[itemName] = (posDineInSnacksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    overallSnacksCounter[itemName].dineIn = overallSnacksCounter[itemName].dineIn + parseInt(item["Addon Count"]);
                } else if(isDelivery){
                    if(source === "zomato"){
                        zomatoDeliverySnacksCounter[item["Item Name"]] = (zomatoDeliverySnacksCounter[item["Item Name"]] || 0) + parseInt(item["Addon Count"]);
                    } else if(source === "swiggy"){
                        swiggyDeliverySnacksCounter[item["Item Name"]] = (swiggyDeliverySnacksCounter[item["Item Name"]] || 0) + parseInt(item["Addon Count"]);
                    }
                    else if(source === "dotpe"){
                        dotpeDeliverySnacksCounter[item["Item Name"]] = (dotpeDeliverySnacksCounter[item["Item Name"]] || 0) + parseInt(item["Addon Count"]);
                    }
                    overallSnacksCounter[itemName].delivery = overallSnacksCounter[itemName].delivery + parseInt(item["Addon Count"]);
                } else {
                    if(source === "pos"){
                        posPickupSnacksCounter[item["Item Name"]] = (posPickupSnacksCounter[item["Item Name"]] || 0) + parseInt(item["Addon Count"]);
                    } else if(source === "dotpe"){
                        dotpePickupSnacksCounter[item["Item Name"]] = (dotpePickupSnacksCounter[item["Item Name"]] || 0) + parseInt(item["Addon Count"]);
                    }
                    overallSnacksCounter[itemName].takeaway = overallSnacksCounter[itemName].takeaway + parseInt(item["Addon Count"]);
                }
            } 
            else if (Utilities.isCustomization(itemName)){

            }
            else {
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                overallDrinksCounter[itemName].count = overallDrinksCounter[itemName].count + parseInt(item["Addon Count"]);
                if(isDineIn){
                    posDineInDrinksCounter[itemName] = (posDineInDrinksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    overallDrinksCounter[itemName].dineIn = overallDrinksCounter[itemName].dineIn + parseInt(item["Addon Count"]);
                } else if(isDelivery){
                    if(source === "zomato"){
                        zomatoDeliveryDrinksCounter[itemName] = (zomatoDeliveryDrinksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    } else if(source === "swiggy"){
                        swiggyDeliveryDrinksCounter[itemName] = (swiggyDeliveryDrinksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    }
                    else if(source === "dotpe"){
                        dotpeDeliveryDrinksCounter[itemName] = (dotpeDeliveryDrinksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    }
                    overallDrinksCounter[itemName].delivery = overallDrinksCounter[itemName].delivery + parseInt(item["Addon Count"]);
                } else {
                    if(source === "pos"){
                        posPickupDrinksCounter[itemName] = (posPickupDrinksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    }else if(source === "dotpe"){
                        dotpePickupDrinksCounter[itemName] = (dotpePickupDrinksCounter[itemName] || 0) + parseInt(item["Addon Count"]);
                    }
                    overallDrinksCounter[itemName].takeaway = overallDrinksCounter[itemName].takeaway + parseInt(item["Addon Count"]);
                }
            }
        }
        let customerName = '';
        if(arr.length > 0){ customerName = arr[0].userName;}
        this.setState({ 
            data : arr, 
            customerName, 
            overallDrinksCounter,
            overallSnacksCounter,
            overallComboCounter,
            overallMerchCounter,
            posPickupDrinksCounter,
            posPickupSnacksCounter,
            posPickupComboCounter,
            posDineInDrinksCounter,
            posDineInSnacksCounter,
            posDineInComboCounter,
            zomatoDeliveryDrinksCounter,
            zomatoDeliverySnacksCounter,
            zomatoDeliveryComboCounter,
            swiggyDeliveryDrinksCounter,
            swiggyDeliverySnacksCounter,
            swiggyDeliveryComboCounter,
            dotpePickupDrinksCounter,
            dotpePickupSnacksCounter,
            dotpePickupComboCounter,
            dotpeDeliveryDrinksCounter,
            dotpeDeliverySnacksCounter,
            dotpeDeliveryComboCounter,
            orderCount : arr.length, 
            snackCount, 
            drinksCount, 
            merchCount,
            money});
    }
    
    getOrders = (newStatus)=>{
        this.loadOrders();
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    render(){
        const { 
            overallDrinksCounter,
            overallSnacksCounter,
            overallComboCounter,
            posPickupDrinksCounter,
            posPickupSnacksCounter,
            posDineInDrinksCounter,
            posDineInSnacksCounter,
            zomatoDeliveryDrinksCounter,
            zomatoDeliverySnacksCounter,
            swiggyDeliveryDrinksCounter,
            swiggyDeliverySnacksCounter,
            dotpePickupDrinksCounter,
            dotpePickupSnacksCounter,
            dotpeDeliveryDrinksCounter,
            dotpeDeliverySnacksCounter,
            drinksCount, 
            snackCount, 
            cash,
            startDate, 
            endDate,
            revenueTotals,
            unknownOrderCount,
            loading
         } = this.state;

        let totalRevenue = 0.0;
        let totalOrderCount = 0;
        for(let source of revenueTotals){
            totalRevenue += parseFloat(source.Revenue);
            totalOrderCount += parseInt(source["Order Count"]);
        }

        return <div className='reportContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                { loading ? <Loader/> : <React.Fragment>
                    <p className='orderCount'>{totalOrderCount} Orders</p>
                    <p className='orderCount unknownOrderCount'>{unknownOrderCount} Unknown Orders</p>
                    <p className='orderCount'>Average Order Value : ₹{Utilities.formatIndianCurrency(parseFloat(totalRevenue) / totalOrderCount)}</p>
                    {(!Utilities.isNullOrEmpty(cash)) ? <p className='orderCount money'>Cash Collected : ₹{Utilities.formatIndianCurrency(cash)}</p> : ''}
                    <p className='moneyLarge'>₹{Utilities.formatIndianCurrency(totalRevenue)}</p>
                    <div className="revenueSplits">
                    {revenueTotals.map((source,index)=>{
                        return <div key={`${source.Source}${index}`}>
                        <p className='sourceTitle'>{source.Source}</p>
                        <p className='orderCount'>{source["Order Count"]} Orders</p>
                        <p className={`money`}>₹{Utilities.formatIndianCurrency(parseFloat(source["Revenue"]))}</p>
                        <p className={`money`}>AOV : ₹{Utilities.formatIndianCurrency(parseFloat(source["Revenue"]) / source["Order Count"])}</p>
                        </div>
                    })}
                    </div>
                    <div className='dateContainer'>
                    <input  disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input  disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='1x'/>
                    </div>
                    </div>
                    </React.Fragment>}
            </div>
            <div className="countsTableContainer">
            { loading ? <Loader/> : 
                <table className="countsTable">
                    <tr>
                        <td className="hideBorder" rowSpan="2"></td>
                        <td rowSpan="2">Dine In</td>
                        <td colSpan="2">Takeaway</td>
                        <td colSpan="3">Delivery</td>
                        <td rowSpan="2">Total</td>
                    </tr>
                    <tr>
                        <td>In Store</td>
                        <td>Dotpe</td>
                        <td>Zomato</td>
                        <td>Swiggy</td>
                        <td>Dotpe</td>
                    </tr>
                    <tr>
                        <td>Drinks</td>
                        <td><Totals title={'In-Store Dine In Drinks'} data={posDineInDrinksCounter}/></td>
                        <td><Totals title={'In-Store Takeaway Drinks'} data={posPickupDrinksCounter}/></td>
                        <td><Totals title={'Dotpe Takeaway Drinks'} data={dotpePickupDrinksCounter}/></td>
                        <td><Totals title={'Zomato Delivery Drinks'} data={zomatoDeliveryDrinksCounter}/></td>
                        <td><Totals title={'Swiggy Delivery Drinks'} data={swiggyDeliveryDrinksCounter}/></td>
                        <td><Totals title={'Dotpe Delivery Drinks'} data={dotpeDeliveryDrinksCounter}/></td>
                        <td>{drinksCount}</td>
                    </tr>
                    <tr>
                        <td>Snacks</td>
                        <td><Totals title={'In-Store Dine In Snacks'} data={posDineInSnacksCounter}/></td>
                        <td><Totals title={'In-Store Takeaway Snacks'} data={posPickupSnacksCounter}/></td>
                        <td><Totals title={'Dotpe Takeaway Snacks'} data={dotpePickupSnacksCounter}/></td>
                        <td><Totals title={'Zomato Delivery Snacks'} data={zomatoDeliverySnacksCounter}/></td>
                        <td><Totals title={'Swiggy Delivery Snacks'} data={swiggyDeliverySnacksCounter}/></td>
                        <td><Totals title={'Dotpe Delivery Snacks'} data={dotpeDeliverySnacksCounter}/></td>
                        <td>{snackCount}</td>
                    </tr>
                    {/* <tr>
                        <td>Combo</td>
                        <td><Totals title={'In-Store Dine In Combo'} data={posDineInComboCounter}/></td>
                        <td><Totals title={'In-Store Takeaway Combo'} data={posPickupComboCounter}/></td>
                        <td><Totals title={'Dotpe Takeaway Combo'} data={dotpePickupComboCounter}/></td>
                        <td><Totals title={'Zomato Delivery Combo'} data={zomatoDeliveryComboCounter}/></td>
                        <td><Totals title={'Swiggy Delivery Combo'} data={swiggyDeliveryComboCounter}/></td>
                        <td><Totals title={'Dotpe Delivery Combo'} data={dotpeDeliveryComboCounter}/> </td>
                    </tr> */}
                </table> }
            </div>
                <StatsTable loading={loading} title={'Combo Sold'} data={overallComboCounter}/>   
                <StatsTable loading={loading} title={'Drinks Sold (Including Combo)'} data={overallDrinksCounter}/>
                <StatsTable loading={loading} title={'Snacks Sold (Including Combo)'} data={overallSnacksCounter}/>          
            </div>
            </div>

    } 
}

export default Reports;