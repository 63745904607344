import React from 'react';
import './ConsumptionTable.css';
import Utilities from '../../../js/Utilities';
import Loader from '../Loader/Loader';
import { CSVLink } from "react-csv";

class ConsumptionTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

render(){
    const { startDate, endDate, data, branch, title, loading, recipeConfig } = this.props;
    if(recipeConfig.length <= 0 || Utilities.isNullOrEmpty(data)){
        return null;
    }

    let ingredients = [];
    let ingTracker = {};
    let i = 0;
    let itemHash = {};
    if(!loading){
    for(let item of data){
        itemHash[item["Item Name"]] = { largeCount : item["Large Count"], regularCount : item["Regular Count"]};
    }
    for(let ing of recipeConfig){
        let temp = {name : ing.ingredientName, quantity : 0.0, batchQty:0.0, batchSize : ing.batchSize,unit:ing.unit};
        let item = itemHash[ing.itemName];
        if(item){
            temp.quantity += ((item.largeCount || 0) * (ing.large || 0)) + ((item.regularCount || 0) * (ing.regular || 0));
            temp.batchQty += temp.quantity / ing.batchSize;
        }
        if(Utilities.isNullOrEmpty(ingTracker[temp.name])){
            ingTracker[temp.name] = {qty:temp.quantity,bQty:temp.batchQty, i:i};
            ingredients.push(temp);
            i++;
        } else {
            ingTracker[temp.name].qty += temp.quantity;
            ingTracker[temp.name].bQty += temp.batchQty;
            ingredients[ingTracker[temp.name].i].quantity = ingTracker[temp.name].qty;
            ingredients[ingTracker[temp.name].i].batchQty = ingTracker[temp.name].bQty;
        }
    }

    //Round up to Nearest Batch
    for(let i=0;i<ingredients.length;i++){
        ingredients[i].batchQty = Math.ceil(ingredients[i].batchQty);
        ingredients[i].quantity = ingredients[i].batchQty * ingredients[i].batchSize;
    }    }

    const headers = [
        { label: "Ingredient Name", key: "name" },
        { label: "Quantity", key: "quantity" },
        { label: "Batch Quantity", key: "batchQty" }
      ];
    return <div className='consumptionPredictionTable'>
        {loading ? <Loader/> :
            <React.Fragment>
            <div className="titleContainer">
            <h3>{title}</h3>    
            </div>
            {
                ingredients.length ? <CSVLink 
                className={"csvButton"}
                data={ingredients}
                headers={headers}
                filename={`${branch}-${startDate} to ${endDate}-Consumption.csv`}
              >
                Download CSV
              </CSVLink> : ''
            }
            { ingredients.length > 0 ? 
                ingredients.map((item, index)=>{
                    return item.name ? <div key={`flavorRow${index}`} style={{backgroundColor : "#485460" }} className={`statsRow`}>
                         <div className='flavourContainer'><div className='countTag'>{item.quantity.toFixed(2)} {item.unit}</div>
                         <div className='countTag'>{(item.batchQty).toFixed(2)} batches ({item.batchSize}{item.unit})</div>
                        <div className="flavourBreakDown"><span>{item.name}</span></div>
                </div></div> : '';}) : <p>There is No Data</p>
            } 
        </React.Fragment> }
        </div>
}

}

export default ConsumptionTable;