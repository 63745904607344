import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUtensils, faHome, faChartBar, faCogs, faSortAmountDown, faFlask, faChartLine} from '@fortawesome/free-solid-svg-icons';
import {
    Link
  } from "react-router-dom"
import Utilities from '../../../js/Utilities';
import './ReportsHeader.css';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';



function HeaderButton(props){
    return <Link className='headerBtn' to={props.to}> 
    <FontAwesomeIcon className='btnIcon' icon={props.icon} size='2x'/>
</Link>
  }

function ReportsHeader(props){

    if(firebase.auth().currentUser && !firebase.auth().currentUser.email.endsWith("@gottea.in") && !firebase.auth().currentUser.email.endsWith("@bluetokaicoffee.com")){
        firebase.auth().signOut();
    }
    return <React.Fragment><div className="headerContainer">
    <HeaderButton to={'/'} icon={faHome}/>
    <HeaderButton to={'/consumption-prediction'} icon={faChartLine}/>
    <HeaderButton to={'/consumption'} icon={faSortAmountDown}/>
    {/* <HeaderButton to={'/announcement'} icon={faScroll}/> */}
    {/* <HeaderButton to={'/customers'} icon={faUsers}/> */}
    {/* <HeaderButton to={'/hourwisesales'} icon={faChartBar}/> */}
    {/* <HeaderButton to={'/croffles'} icon={faUtensils}/> */}
    <div className='headerBtn logOutBtn' onClick={Utilities.logout}> 
        <FontAwesomeIcon className='btnIcon' icon={faSignOutAlt} size='2x'/>
        Log Out
    </div>
</div>
<p className="welcomeMsg">{firebase.auth().currentUser  ? firebase.auth().currentUser.displayName : 'Welcome'}</p>
</React.Fragment>

}

export default ReportsHeader;