import React from 'react';
import Utilities from '../../../js/Utilities';
import './Drop.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import GotTeaBigLogo from '../../../media/GotWhiteLogo.svg';
const moment = require('moment');
class Customers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            browser : Utilities.getCurrentBrowser(),
            currentCounter : 0
        }
        this.timer = null;
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value});
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value});
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    reduceCounter  = ()=>{
        this.setState({
            currentCounter : (this.state.currentCounter-1)
        })
    }

    increaseCounter  = ()=>{
        this.setState({
            currentCounter : (this.state.currentCounter+1)
        })
    }

    render(){
        const { 
            currentCounter
         } = this.state;


        return <div className='dropCounterContainer'>
            <img className="logo" src={GotTeaBigLogo} alt='logo'/>
            <div className='dropCounter'>
            <FontAwesomeIcon className='btnIcon' icon={faMinusCircle} size='10x' onClick={this.reduceCounter}/>
            <p className='counterNumber'>{currentCounter}</p>
            <FontAwesomeIcon className='btnIcon' icon={faPlusCircle} size='10x' onClick={this.increaseCounter}/>
            </div>
            </div>
    } 
}

export default Customers;