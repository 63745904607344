import React from 'react';
import './ConsumptionTable.css';
import Utilities from '../../../js/Utilities';
import Loader from '../Loader/Loader';
import { CSVLink } from "react-csv";



const flavorDic = {
    'Iced Americano':{key :'icedamericano', colour:'#485460'},
    'Hot Americano':{key :'americanohot', colour:'#485460'},
    'Green Matcha' : {key : 'matcha', colour : '#485731'},
    'Korean Banana Milk' :  {key: 'banana', colour: '#E1C758'},
    'Korean Banana Latte' :  {key: 'bananalatte', colour: '#E1C758'},
    'Berry Merry Matcha' : {key : 'berrymerry', colour : ''},
    'Strawberry Matcha' : {key : 'strawberrymatcha', colour : 'linear-gradient(#EAB5C2, #A7C991)', isGradient : true},
    'Earl Grey' : {key : 'earlgrey', colour : '#9AA594'},
    'Hot Earl Grey' : {key : 'earlgreyhot', colour : '#9AA594'},
    'Royal Milk Tea' : {key : 'royalmilktea', colour : '#3853A3'},
    'Hot Royal Milk Tea' : {key : 'royalmilkteahot', colour : '#3853A3'},
    'Ivory Silk' : {key : 'ivorySilk', colour : '#D2BBA2'},
    'Taro Purple' : {key : 'taro', colour : '#634C91'},
    'Caramel Brown' : {key : 'caramel', colour : '#875020'},
    'Classic Black' : {key : 'classic', colour : '#3C3333'},
    'Strawberry Blossom' : {key : 'blossom', colour : '#C44191'},
    'Strawberry Sparkle' : {key : 'sparkle', colour : '#D73663'},
    'Seoul Sesame' : { key : 'seoulsesame', colour : '#828282'},
    'Tropical Thai' : {key : 'thai', colour : '#CA8346'},
    'Scarlet Jamong' : {key : 'jamong', colour : '#FF4D4D'},
    'Grapefruit Iced Tea' : {key : 'grapefruit', colour : '#FF4D4D'},
    'Mango Coconut Milk' : {key : 'mangococonut', colour : '#FF8C00'},
    'Chocolate Milk' : { key: 'chocomilk', colour : '#4A2B25'},
    'Hot Chocolate Milk' : { key: 'chocomilkhot', colour : '#4A2B25'},
    'Mocha Latte' : { key: 'mochalatte', colour : '#583933'},
    'Hot Mocha Latte' : { key: 'mochalattehot', colour : '#583933'},
    'Hibiscus Iced Tea' : {key : 'hibiscus', colour : '#A92D33'},
    'Koko Caphe' : {key : 'kokocaphe', colour : '#756E30'},
    'Peachy Yuja' : {key : 'peachyyuja', colour : '#E08D77'},
    'Peachy Oolong' : {key : 'peachyoolong', colour : '#FABDA4'},
    'Hot Peachy Oolong' : {key : 'peachyoolonghot', colour : '#FABDA4'},
    'Blueberry Croffle' : {key : 'blueberrycroffle', colour : '#634C91'},
    'Strawberry Croffle' : {key : 'strawberrycroffle', colour : '#C44191'},
    'Corn Cheese Croffle' : {key : 'corncheeseecroffle', colour : '#ffbe76'},
    'Custard Croffle' : {key : 'custardcroffle', colour : '#feca57'},
    'Dill & Cheese Croffle' : {key : 'dillcheesecroffle', colour : '#EAB543'},
    'Dill And Cheese Croffle' : {key : 'dillcheesecroffle', colour : '#EAB543'},
    'Original Croffle' : {key : 'dillcheesecroffle', colour : '#875020'},
    'Non - Veg Sandwich' : {key : 'nonvegsandwich', colour : '#eb4d4b'},
    'Non-Veg Sandwich' : {key : 'nonvegsandwich', colour : '#eb4d4b'},
    'Veg Sandwich' : {key : 'nonvegsandwich', colour : '#10ac84'},
    'Got Spicy Fried Chicken' : {key : 'chicken', colour : '#CA8346'},
    'Got Fried Chicken' : {key : 'chicken', colour : '#CA8346'},
    'Caramel Brown Scone' : {key : 'caramelbrownscone', colour : '#875020'},
}

class ConsumptionTable extends React.Component {

    constructor(props){
        super(props);
        this.state = {
        }
    }

render(){
    const { startDate, endDate, data, branch, title, loading,recipeConfig } = this.props;
    // console.log("DATA =>", data);
    // console.log("recipeConfig =>", recipeConfig);
    let stats = [];
    let maxCount = 0;
    for(let key in data){
        if(data[key].count >= maxCount){
            maxCount = data[key].count
        }
        
        stats.push({ 
             name : key, 
             count : data[key].count,
             regularCount : data[key].regularCount,
             largeCount : data[key].largeCount,
             delivery:data[key].delivery, 
             takeaway:data[key].takeaway, 
             dineIn: data[key].dineIn, 
             isGradient:flavorDic[key] ?  flavorDic[key].isGradient :  false, 
             colour : flavorDic[key] ?  flavorDic[key].colour :  '#C1A97E', 
             flavorName : flavorDic[key] ?  flavorDic[key].key :  'generalItem'
            });
    }
    stats.sort(function(a, b){return b.count-a.count});

 

    if(recipeConfig.length <= 0 || Utilities.isNullOrEmpty(data)){
        return null;
    }

    let ingredients = [];
    let ingTracker = {};
    let i = 0;
    for(let ing of recipeConfig){
        let temp = {name : ing.ingredientName, quantity : 0.0, batchQty:0.0, batchSize : ing.batchSize };
        let item = data[ing.itemName];
        if(item){
            temp.quantity += ((item.largeCount || 0) * (ing.large || 0)) + ((item.regularCount || 0) * (ing.regular || 0));
            temp.batchQty += temp.quantity / ing.batchSize;
        }
        if(Utilities.isNullOrEmpty(ingTracker[temp.name])){
            ingTracker[temp.name] = {qty:temp.quantity,bQty:temp.batchQty, i:i};
            ingredients.push(temp);
            i++;
        } else {
            ingTracker[temp.name].qty += temp.quantity;
            ingTracker[temp.name].bQty += temp.batchQty;
            ingredients[ingTracker[temp.name].i].quantity = ingTracker[temp.name].qty;
            ingredients[ingTracker[temp.name].i].batchQty = ingTracker[temp.name].bQty;
        }
    }
    // ingredients.sort(function(a, b){return b.quantity-a.quantity});

    const headers = [
        { label: "Ingredient Name", key: "name" },
        { label: "Quantity", key: "quantity" },
        { label: "Batch Quantity", key: "batchQty" }
      ];
    
    return <div className='consumptionTable'>
        {loading ? <Loader/> :
            <React.Fragment>
            <div className="titleContainer">
            <h3>{title}</h3>    
            </div>
            {
                ingredients.length ? <CSVLink 
                className={"csvButton"}
                data={ingredients}
                headers={headers}
                filename={`${branch}-${startDate} to ${endDate}-Consumption.csv`}
              >
                Download CSV
              </CSVLink> : ''
            }
            { ingredients.length > 0 ? 
                ingredients.map((item, index)=>{
                    return item.name ? <div key={`flavorRow${index}`} style={{backgroundColor : "#485460" }} className={`statsRow`}>
                         <div className='flavourContainer'><div className='countTag'>{item.quantity} g</div>
                         <div className='countTag'>{(item.batchQty).toFixed(2)} batches</div>
                        <div className="flavourBreakDown"><span>{item.name}</span></div>
                </div></div> : '';}) : <p>There is No Data</p>
            } 
        </React.Fragment> }
        </div>
}

}

export default ConsumptionTable;