import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import './Customers.css';
import '../../Common/StatsTable/StatsTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort,faArrowCircleRight, faDownload } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { CSVLink } from "react-csv";
const Client = new Api();
const moment = require('moment');
class Customers extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            browser : Utilities.getCurrentBrowser(),
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD'),
            cash : 0.0,
            cashCount : 0,
            customerName : '',
            money : 0.0,
            data : null,
            stats : null,
            orders:null,
            totalNumberOfOrders:0,
            returnCustomerPercentage:0.0,
            customerData : [],
            sortDirection : 'dsc',
            unknownOrderCount:0,
            loading : true,
            averageOrderValue : 0.0
        }
        this.timer = null;
    }

    handleSort = (sortKey)=>{
        let { customerData, sortDirection } = this.state;
        if(sortDirection === 'asc'){
            customerData = customerData.sort((a,b)=> a[sortKey] - b[sortKey]);
        } else if(sortDirection === 'dsc'){
            customerData = customerData.sort((a,b)=> b[sortKey] - a[sortKey]);
        }
        if(sortKey === 'orderTimestamp'){
            if(sortDirection === 'asc'){
                customerData = customerData.sort((a,b)=> new Date(a[sortKey]) - new Date(b[sortKey]));
            } else if(sortDirection === 'dsc'){
                customerData = customerData.sort((a,b)=> new Date(b[sortKey]) - new Date(a[sortKey]));
            }
        }
        this.setState({
            customerData,
            sortDirection : sortDirection === 'asc' ? 'dsc' : 'asc'
        });
    }

    loadCustomers = async ()=>{
        const {startDate, endDate } = this.state;
        let fbChat = document.querySelector('.fb_dialog');
        if(fbChat){ fbChat.remove();}
        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');
        this.setState({
            loading:true
        },()=>{
            Client.getCustomerData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((data)=>{
                console.log(data);
                this.setState({
                    loading:false,
                    customerData:data
                });
            })
        })

    }
    
    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadCustomers();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value});
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value});
    }

    onUpdateClick = ()=>{
        this.loadCustomers();
    }


    render(){
        const { 
            startDate, 
            endDate,
            returnCustomerPercentage,
            totalNumberOfOrders,
            customerData,
            loading,
            totalCustomers,
            averageOrderValue,
            customerRetentionRate,
            newCustomersCount
         } = this.state;

        // const csvData = customerData.map((cust, index)=>{
        //     return [
        //         cust.name ? Utilities.formatName(cust.name) : 'Unknown',
        //         cust.count,
        //         cust.revenue,
        //         moment(cust.orderTimestamp).format("YYYY-MM-DD"),
        //         cust.daysSinceLastVisit,
        //         cust.mobileNo
        //     ];
        // });

        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');

        return <div className='customersContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                <div className='dateContainer'>
                    <input  disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input  disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='2x'/>
                    </div>
                        {/* <div className='updateBtn downloadBtn'> 
                        <CSVLink filename={`Customers Data [${startDateString}->${endDateString}]`} data={csvData} headers={[
                        'Name',
                        '# Of Orders',
                        'Amount Spent',
                        'Last Order Time',
                        'Days Since Last Visit',
                        'Phone #'
                    ]}>
                            <FontAwesomeIcon className='btnIcon' icon={faDownload} size='2x'/>
                            </CSVLink>
                        </div> */}
                 </div>
            </div>       
            {/* <div className='wrapper'>
                <div className="infoDiv">
                    { loading ? <Loader/> : 
                    <div className="numberBubbles">
                        <p className="numberBubble"># Customers<span className="numberCircle">{totalCustomers}</span></p>
                        <p className="numberBubble">New Customers<span className="numberCircle">{newCustomersCount}</span></p>
                        <p className="numberBubble">Retention Rate<span className="numberCircle">{customerRetentionRate.toFixed(2)}%</span></p>
                        <p className="numberBubble"># Orders<span className="numberCircle">{totalNumberOfOrders}</span></p>
                        <p className="numberBubble">Repeat Customer Rate<span className="numberCircle">{returnCustomerPercentage.toFixed(2)}% ({((returnCustomerPercentage / 100) * totalCustomers).toFixed(0)})</span></p>
                    </div>
                    }
                </div>
            </div> */}
            <div className='wrapper'>
                <div className="infoDiv">
                { loading ? <Loader/> : 
                    <table className="countsTable">
                        <tbody>
                        <tr>
                            <td>Name <FontAwesomeIcon className='sortIcon' icon={faSort} size='1x'/></td>
                            <td># of Drinks <FontAwesomeIcon onClick={()=> this.handleSort('drinksCount')} className='sortIcon' icon={faSort} size='1x'/></td>
                            <td># of Snacks <FontAwesomeIcon onClick={()=> this.handleSort('snacksCount')} className='sortIcon' icon={faSort} size='1x'/></td>
                            <td>Amount Spent <FontAwesomeIcon onClick={()=> this.handleSort('moneySpentSoFar')} className='sortIcon' icon={faSort} size='1x'/></td>
                            <td>First Order Time <FontAwesomeIcon onClick={()=> this.handleSort('firstOrderTime')} className='sortIcon' icon={faSort} size='1x'/></td>
                            <td>Phone #</td>
                        </tr>
                        {
                            customerData.map((cust, index)=>{
                                return <tr key={`customerRow${index}`}>
                                    { cust.name ? <td>{Utilities.formatName(cust.name)}</td> : <td className="unknownName">Unknown</td>}
                                    <td>{cust.drinksCount}</td>
                                    <td>{cust.snacksCount}</td>
                                    <td>₹{cust.moneySpentSoFar}</td>
                                    <td>{cust.firstOrderTime}</td>
                                    <td>{cust.id}</td>    
                                </tr>;
                            })
                        }
                        </tbody>
                    </table> }
                </div>     
            </div>  
            </div>
            </div>
    } 
}

export default Customers;