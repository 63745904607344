import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import PrintIcon from '@mui/icons-material/Print';

const PrintButton = ({ contentId }) => {
  const iframeRef = useRef();

  const handlePrint = () => {
    const content = document.getElementById(contentId);
    const iframe = iframeRef.current;
    if (content) {
      console.log(content.innerHTML);
      iframe.contentDocument.write(`
        <html>
          <head>
          <style>
          @media print {
            table {
                visibility: visible;
                left: 0;
                top: 0;
                width: 100%;
                border-width:0;
              }
              table table{
                border-collapse: collapse;
                border:none;
                margin-bottom:10px;
              }

              table tr {
                page-break-inside: avoid;
              }

              table table td, table table th{
                color:black;
                border-collapse: collapse;
                border: 1px solid #000;
                padding: 0.5rem;
                text-align:left;
                max-width:50px;
              }
              table table th{
                text-transform:uppercase;
              }
            }
            </style>
            <title>Print</title>
          </head>
          <body>
            ${content.innerHTML}
          </body>
        </html>
      `);
      iframe.contentDocument.close();
      iframe.contentWindow.print();
    }
  };

  return (
    <>
    <Button sx={{mb:1,maxWidth:'200px',textAlign:'center',ml:'auto',mr:'auto'}} variant="contained" color="warning" onClick={handlePrint} endIcon={<PrintIcon/>}>
      Print
    </Button>
    <iframe title="Print Frame" ref={iframeRef} style={{display: 'none'}} />
    </>
  );
};

export default PrintButton;