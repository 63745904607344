import { SEARCH, SWITCH_TAB, SET_MUTED, SET_DATA } from '../actions/types';

const initialState = {
    muted : false,
    tabSelected : 'ACTIVE',
    data : null,
    filteredData : null,
    filtered : false,
    searchTerm : ''
}

const mainReducer = ( state = initialState, action ) => { 
    switch (action.type) {
       case SEARCH:
            return {
                ...state,
                searchTerm : action.searchTerm,
                filteredData : action.filteredData,
                filtered : (action.searchTerm !== '')
            };
        case SWITCH_TAB:
            return {
                ...state,
                tabSelected : action.tabSelected
            };   
        case SET_MUTED:
            return {
                ...state,
                muted : action.muted
            };        
        case SET_DATA:
            return {
                ...state,
                data : action.data
            };                   
        default:
            return {...state};
    }
}

export default mainReducer;