import React from 'react';
import './Totals.css';
import Utilities from '../../../js/Utilities';

const flavorDic = {
    'Green Matcha' : 'matcha',
    'Berry Merry Matcha' : 'berrymerry',
    'Ivory Silk' : 'ivorySilk',
    'Taro Purple' : 'taro',
    'Caramel Brown' : 'caramel',
    'Classic Black' : 'classic',
    'Strawberry Blossom' : 'blossom',
    'Strawberry Sparkle' : 'sparkle',
    'Tropical Thai' : 'thai',
    'Scarlet Jamong' : 'jamong',
    'Grapefruit Iced Tea' :'grapefruit',
    'Koko Caphe' : 'kokocaphe',
    'Got Spicy Fried Chicken' : 'chicken'
}

function Totals(props){
    const { data } = props;
    let stats = [];
    for(let key in data){
        stats.push({ name : key, count : data[key], flavorName : flavorDic[key] || 'generalItem' });
    }
    stats.sort(function(a, b){return b.count-a.count});
    return <React.Fragment>        
            <h3>{stats.length > 0 ? Utilities.getTotal(stats) : '0'}</h3>
        </React.Fragment>
}

export default Totals;