
import React, { useState } from 'react';
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, IconButton } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '1rem',
  },
  button: {
    margin: '1rem',
  },
  formControl: {
    margin: '0.5rem 0',
    minWidth: '240px',
  },
};

const dummyTasks = [
  { name: 'Clean Tables', description: 'Wipe down all tables', scheduleType: 'Daily' },
  { name: 'Stock Inventory', description: 'Check and stock inventory', scheduleType: 'Weekly' },
  { name: 'Employee Briefing', description: 'Conduct morning briefing', scheduleType: 'Daily' },
];

const AdminTaskManager = () => {
  const [task, setTask] = useState({
    name: '',
    description: '',
    scheduleType: '',
    dayOfWeek: '',
    taskDate: '',
  });

  const [tasks, setTasks] = useState(dummyTasks);
  const [open, setOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTask({
      ...task,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    if (editIndex !== null) {
      tasks[editIndex] = task;
      setTasks([...tasks]);
      setEditIndex(null);
    } else {
      setTasks([...tasks, task]);
    }
    setOpen(false);
  };

  const handleEdit = (index) => {
    setTask(tasks[index]);
    setEditIndex(index);
    setOpen(true);
  };

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    const [removed] = tasks.splice(source.index, 1);
    tasks.splice(destination.index, 0, removed);
    setTasks([...tasks]);
  };

  return (
    <div style={styles.container}>
      <h1>Admin Task Manager</h1>

      <Button style={styles.button} variant="contained" color="primary" onClick={() => setOpen(true)}>
        Create Task
      </Button>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="taskList">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {tasks.map((t, index) => (
                <Draggable key={index} draggableId={String(index)} index={index}>
                  {(provided) => (
                    <ListItem ref={provided.innerRef} {...provided.draggableProps}>
                      <IconButton edge="start" {...provided.dragHandleProps}>
                        <DragIndicatorIcon />
                      </IconButton>
                      <ListItemText primary={t.name} secondary={t.description} />
                      <IconButton edge="end" onClick={() => handleEdit(index)}>
                        <EditIcon />
                      </IconButton>
                    </ListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{editIndex !== null ? 'Edit Task' : 'Create New Task'}</DialogTitle>
        <DialogContent>
          <FormControl style={styles.formControl}>
            <TextField name="name" label="Task Name" value={task.name} onChange={handleChange} />
          </FormControl>
          <FormControl style={styles.formControl}>
            <TextField name="description" label="Task Description" value={task.description} onChange={handleChange} />
          </FormControl>
          <FormControl style={styles.formControl}>
            <InputLabel>Schedule Type</InputLabel>
            <Select name="scheduleType" value={task.scheduleType} onChange={handleChange}>
              <MenuItem value="Daily">Daily</MenuItem>
              <MenuItem value="Weekly">Weekly</MenuItem>
              <MenuItem value="One-Time">One-Time</MenuItem>
            </Select>
          </FormControl>
          {task.scheduleType === 'Weekly' && (
            <FormControl style={styles.formControl}>
              <InputLabel>Day of Week</InputLabel>
              <Select name="dayOfWeek" value={task.dayOfWeek} onChange={handleChange}>
                <MenuItem value="Monday">Monday</MenuItem>
                <MenuItem value="Tuesday">Tuesday</MenuItem>
                <MenuItem value="Wednesday">Wednesday</MenuItem>
                <MenuItem value="Thursday">Thursday</MenuItem>
                <MenuItem value="Friday">Friday</MenuItem>
                <MenuItem value="Saturday">Saturday</MenuItem>
                <MenuItem value="Sunday">Sunday</MenuItem>
              </Select>
            </FormControl>
          )}
          {task.scheduleType === 'One-Time' && (
            <FormControl style={styles.formControl}>
              <TextField name="taskDate" label="Task Date" type="date" value={task.taskDate} onChange={handleChange} InputLabelProps={{ shrink: true }} />
            </FormControl>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSubmit} color="primary">
            {editIndex !== null ? 'Update' : 'Create'}
          </Button>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdminTaskManager;