import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import React, { Component } from 'react';
import './CroffleBoard.css';
import moment from 'moment';
import Loader from '../../Common/Loader/Loader';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import 'firebase/compat/auth';

const flavorDic = {
  'Green Matcha': { key: 'matcha', colour: '#485731' },
  'Banana Milk': { key: 'banana', colour: '#E1C758' },
  'Berry Merry Matcha': { key: 'berrymerry', colour: '' },
  'Strawberry Matcha': {
    key: 'strawberrymatcha',
    colour: 'linear-gradient(#EAB5C2, #A7C991)',
    isGradient: true,
  },
  'Earl Grey': { key: 'earlgrey', colour: '#9AA594' },
  'Royal Milk Tea': { key: 'royalmilktea', colour: '#3853A3' },
  'Ivory Silk': { key: 'ivorySilk', colour: '#D2BBA2' },
  'Taro Purple': { key: 'taro', colour: '#634C91' },
  'Caramel Brown': { key: 'caramel', colour: '#875020' },
  'Classic Black': { key: 'classic', colour: '#3C3333' },
  'Strawberry Blossom': { key: 'blossom', colour: '#C44191' },
  'Strawberry Sparkle': { key: 'sparkle', colour: '#D73663' },
  'Seoul Sesame': { key: 'seoulsesame', colour: '#828282' },
  'Tropical Thai': { key: 'thai', colour: '#CA8346' },
  'Scarlet Jamong': { key: 'jamong', colour: '#FF4D4D' },
  'Grapefruit Iced Tea' : {key : 'grapefruit', colour : '#FF4D4D'},
  'Hibiscus Iced Tea' : {key : 'hibiscus', colour : '#A92D33'},
  'Koko Caphe': { key: 'kokocaphe', colour: '#756E30' },
  'Peachy Yuja': { key: 'peachyyuja', colour: '#E08D77' },
  'Peachy Oolong': { key: 'peachyoolong', colour: '#FABDA4' },
  'Blueberry Croffle': { key: 'blueberrycroffle', colour: '#634C91' },
  'Corn Cheese Croffle': { key: 'corncheeseecroffle', colour: '#ffbe76' },
  'Custard Croffle': { key: 'custardcroffle', colour: '#feca57' },
  'Dill & Cheese Croffle': { key: 'dillcheesecroffle', colour: '#EAB543' },
  'Dill And Cheese Croffle': { key: 'dillcheesecroffle', colour: '#EAB543' },
  'Original Croffle': { key: 'dillcheesecroffle', colour: '#875020' },
  'Non - Veg Sandwich': { key: 'nonvegsandwich', colour: '#eb4d4b' },
  'Non-Veg Sandwich': { key: 'nonvegsandwich', colour: '#eb4d4b' },
  'Veg Sandwich': { key: 'nonvegsandwich', colour: '#10ac84' },
  'Got Spicy Fried Chicken': { key: 'chicken', colour: '#CA8346' },
  'Got Fried Chicken': { key: 'chicken', colour: '#CA8346' },
  'Caramel Brown Scone': { key: 'caramelbrownscone', colour: '#875020' },
};

const firebaseConfig = {
  apiKey: 'AIzaSyAB10QoTXFcdUCaVSbZntf_d_cSj7OSjHM',
  authDomain: 'gottea-3daa4.firebaseapp.com',
  databaseURL: 'https://gottea-3daa4.firebaseio.com',
  projectId: 'gottea-3daa4',
  storageBucket: 'gottea-3daa4.appspot.com',
  messagingSenderId: '691417546594',
  appId: '1:691417546594:web:536ef6d0c379fb5947b63b',
  measurementId: 'G-ED1W31MB1J',
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);
// Use these for db & auth
const db = firebaseApp.firestore();

type Croffle = {
  name: string;
  orderType: string;
};



function CroffleBox({buttonClick, data}){
  let isDineIn = data.orderType.toLowerCase() === "dinein";
  let orderType = data.orderType;
  let orderTime = moment(data.createdAt.toDate()).format("hh:mm A");
  let orderNo = data.orderNumber;
  let status = data.croffleStatus;
  let buttonTitle = 'Start Preparing';
  if(status === 'Preparing'){
    buttonTitle = 'Mark Ready';
  } else if(status === 'Ready'){
    buttonTitle = 'Complete';
  }
  if(isDineIn){
    orderType = 'Dine In';
  } else {
    orderType = 'Takeaway';
    if(data.orderType.toLowerCase() === "delivery"){
      orderNo = orderNo.substring(orderNo.length - 4,  orderNo.length);
      orderType = 'Delivery';
    }
  }
  return <div
      className="croffleBox"
    >
  <span className='orderNo'>#{orderNo}</span>
  <span className='orderTime'>{orderTime}</span>
  <div className='croffleInfo'>
  <span className={'croffleLabel'}       style={{
        backgroundColor: flavorDic[data.name]
          ? flavorDic[data.name].colour
          : '#C1A97E',
          borderColor: flavorDic[data.name]
          ? flavorDic[data.name].colour
          : '#C1A97E'
      }}>{data.name}</span>

  <div className="packingLabel" style={
    {backgroundColor:(orderType.toLowerCase() === 'takeaway' || orderType.toLowerCase() === 'delivery') ? '#e74c3c' : '#47908A'}}>{orderType}</div>
  </div>
  <div className='actionBtn' onClick={()=>{buttonClick(data.id, buttonTitle);}}>{buttonTitle}</div>
</div>;
}

export default class App extends Component {
  constructor() {
    super({});
    this.state = {
      newCroffles: [],
      preparingCroffles: [],
      readyCroffles:[]
    };
    this.audioThingy = React.createRef();
  }

  loadCroffles() {
    db.collection('croffleTracker')
      .where('croffleStatus', '==', 'Order Placed')
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const newCroffles: Croffle[] = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          newCroffles.push(data);
        });
        this.setState({
          newCroffles
        },()=>{
          if(this.audioThingy && this.audioThingy.play){
            this.audioThingy.play();
          }
        });
      });
    db.collection('croffleTracker')
      .where('croffleStatus', '==', 'Preparing')
      .orderBy('createdAt', 'asc')
      .onSnapshot((querySnapshot) => {
        const preparingCroffles: Croffle[] = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          preparingCroffles.push(data);
        });
        this.setState({
          preparingCroffles,
        });
      });
      db.collection('croffleTracker')
      .where('croffleStatus', '==', 'Ready')
      .orderBy('createdAt', 'desc')
      .onSnapshot((querySnapshot) => {
        const readyCroffles: Croffle[] = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          data.id = doc.id;
          readyCroffles.push(data);
        });
        this.setState({
          readyCroffles
        });
      });
  }

  componentDidMount(){
    firebase.auth().onAuthStateChanged(user => {
        let loggedIn = !!user;
        if(loggedIn){
            this.loadCroffles();
        } else {
            window.location.href = '/login';
        }
    });
}

  handleButtonClick = async (croffleId, action)=>{
    console.log(croffleId, action);
    if(action.toLowerCase() === "start preparing"){
      let docRef = db.collection('croffleTracker').doc(croffleId);
      await docRef.update({
        croffleStatus : "Preparing"
      });
    }
    if(action.toLowerCase() === "mark ready"){
      let docRef = db.collection('croffleTracker').doc(croffleId);
      await docRef.update({
        croffleStatus : "Ready"
      });
    }
    if(action.toLowerCase() === "complete"){
      let docRef = db.collection('croffleTracker').doc(croffleId);
      await docRef.update({
        croffleStatus : "Closed"
      });
    }
  }

  render() {
    let { newCroffles, preparingCroffles, readyCroffles } = this.state;
     return (
       <div style={{margin:'auto'}}>
           <ReportsHeader/>
         {newCroffles.length > 0 ? <audio loop ref={(input) => {this.audioThingy = input}} src={'https://firebasestorage.googleapis.com/v0/b/gottea-3daa4.appspot.com/o/notification.wav?alt=media&token=f2616c6d-5681-428f-8ef8-0e3940731698'} style={{ display: 'none' }} /> : ''}
      <div className="crofflesContainer">
        <div className="crofflesColumn">
          <div><h2 className="columnLabel">New</h2></div>
          <div className="crofflesList">
        {newCroffles.map((item) => {
          return <CroffleBox buttonClick={this.handleButtonClick} data={item}/>;
        })}
        </div>
        </div>
        <div className="crofflesColumn">
        <div><h2 className="columnLabel">Preparing</h2></div>
        <div className="crofflesList">
        {preparingCroffles.map((item) => {
          return <CroffleBox buttonClick={this.handleButtonClick} data={item}/>;
        })}
        </div>
        </div>
        <div className="crofflesColumn">
        <div><h2 className="columnLabel">Ready</h2></div>
        <div className="crofflesList">
        {readyCroffles.map((item) => {
          return <CroffleBox buttonClick={this.handleButtonClick} data={item}/>;
        })}
        </div>
        </div>
      </div>
    </div>);
  }
}
