import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import ConsumptionTable from '../../Common/ConsumptionTable/ConsumptionTable';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './ConsumptionReports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const Client = new Api();
const moment = require('moment-timezone');
class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            recipeConfig:[],
            loading:true,
            viewMode:'day',
            storeMode:'all',
            browser : Utilities.getCurrentBrowser(),
            currentMonth:moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM'),
            goalKey:`all-${moment.tz(moment(), 'Asia/Kolkata').format('MM-YYYY')}`,
            startDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            endDate : moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM-DD'),
            cash : 0.0,
            cashCount : 0,
            revenueTotals : [],
            branchTotals:[],
            customerName : '',
            money : 0.0,
            data : null,
            stats : null,
            orders:null,
            addOnItems : [],
            orderItems : [],
            unknownOrderCount : 0
        }
        this.timer = null;
    }

    loadOrders = async ()=>{
        const {startDate, endDate, storeMode, viewMode, currentMonth } = this.state;
        let fbChat = document.querySelector('.fb_dialog');
        if(fbChat){ fbChat.remove();}
        let startDateString = moment.tz(startDate,'Asia/Kolkata').format('YYYY-MM-DD');
        let endDateString = moment.tz(endDate,'Asia/Kolkata').format('YYYY-MM-DD');

        if(viewMode === "month"){
            startDateString = moment(currentMonth).startOf('month').format('YYYY-MM-DD');
            endDateString   = moment(currentMonth).endOf('month').format('YYYY-MM-DD');
        }

        this.setState({
            loading:true
        },()=>{
            Client.getGotTeaOrderData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf(),storeMode).then((orderData)=>{
                Client.getRecipeConfig().then((recipeData)=>{
                    this.setState({
                        recipeConfig:recipeData.ingredients,
                        loading:false,
                        orderItems:orderData.items,
                    },()=>{
                        this.filterOrders();
                    });
                })
            }).catch((err)=>{
                console.log('GET GOT TEA DATA ERROR =>', err);
            });
        })
    }

    filterOrders = () =>{
        const { orderItems} = this.state;
        let overallDrinksCounter = {};
        let overallSnacksCounter = {};
        let overallComboCounter = {};
        let overallOtherCounter = {};

        let arr = [];
        for(const item of orderItems){
            let orderType = item.Service.toLowerCase();
            let source = item.Source.toLowerCase();
            let itemName = item["Item Name"].trim();
            if(itemName.trim() === "Peachy  Oolong"){
                itemName = "Peachy Oolong";
            }
            if(itemName.toLowerCase().includes("croffle")){
                if(itemName.trim() === "Bubble Tea + Croffle Combo" || itemName.trim() === "1 Bubble Tea + Original Croffle"){
                    itemName = "1 Bubble Tea + 1 Croffle";
                }
                itemName = itemName.replace("(-10%)","").replace("(-10%)","").replace("Croffle - Original","Original Croffle").trim();
                if(itemName.trim() === "Corn and Cheese Croffle"){
                    itemName = "Corn Cheese Croffle";
                }
                if(itemName.trim() === "Dill And Cheese Croffle"){
                    itemName = "Dill & Cheese Croffle";
                }
                if(itemName.trim() === "Dill &amp; Cheese Croffle"){
                    itemName = "Dill & Cheese Croffle";
                }
            } else if(itemName.toLowerCase().includes("sandwich")){
                if(itemName.trim() === "Sandwich Veg"){
                    itemName = "Veg Sandwich";
                }
                if(itemName.trim() === "Non-Veg Sandwich" || itemName.trim() === "Sandwich Non Veg" || itemName.trim() === "Sandwich Chicken"){
                    itemName = "Non - Veg Sandwich";
                }
            } else {
                itemName = itemName.replace("Bubble Tea", "").replace(" (10% Off)","").replace("(-10%)","").replace("[No Bubbles]","").replace("No Bubbles","").replace("[New]","").trim();
            }
            if(itemName.toLowerCase().trim() === "banana milk"){
                itemName = "Korean Banana Milk";
            }

            if(Utilities.isSnack(itemName)){
                if(Utilities.isNullOrEmpty(overallSnacksCounter[itemName])){
                    overallSnacksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0, largeCount:0, regularCount:0};
                }
                overallSnacksCounter[itemName].count = overallSnacksCounter[itemName].count + parseInt(item["Item Count"]);
                overallDrinksCounter[itemName].regularCount += parseInt(item["Item Count"]);
                overallDrinksCounter[itemName].count += parseInt(item["Item Count"]);
            } 
            
            else if(item["Category"].toLowerCase().trim() === "coffee"){
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0, largeCount:0, regularCount:0};
                }
                overallDrinksCounter[itemName].count = overallDrinksCounter[itemName].count + parseInt(item["Item Count"]);
                overallDrinksCounter[itemName].largeCount += parseInt(item["Large Count"]);
                overallDrinksCounter[itemName].regularCount += parseInt(item["Regular Count"]);
            } 
            else if(item["Category"].toLowerCase().trim() === "hot drinks" || item["Category"].toLowerCase().trim() === "classic"){
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0, largeCount:0, regularCount:0};
                }
                overallDrinksCounter[itemName].count = overallDrinksCounter[itemName].count + parseInt(item["Item Count"]);
                overallDrinksCounter[itemName].largeCount += parseInt(item["Large Count"]);
                overallDrinksCounter[itemName].regularCount += parseInt(item["Regular Count"]);
            }
            else if(item["Category"] !== "Snacks" && item["Category"] !== "Bubble Tea"){
            }
            else {
                if(Utilities.isNullOrEmpty(overallDrinksCounter[itemName])){
                    overallDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0, largeCount:0, regularCount:0};
                }
                overallDrinksCounter[itemName].count = overallDrinksCounter[itemName].count + parseInt(item["Item Count"]);
                overallDrinksCounter[itemName].largeCount += parseInt(item["Large Count"]);
                overallDrinksCounter[itemName].regularCount += parseInt(item["Regular Count"]);
            }
        }
        let customerName = '';
        if(arr.length > 0){ customerName = arr[0].userName;}
        this.setState({ 
            data : arr, 
            customerName, 
            overallDrinksCounter,
            overallSnacksCounter,
            overallOtherCounter,
            overallComboCounter,
            orderCount : arr.length
            });
    }
    
    getOrders = (newStatus)=>{
        this.loadOrders();
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
            this.filterOrders();
        });
    }

    setMonth = async (e) => {
        const startDate = moment(e.target.value).startOf('month').format('YYYY-MM-DD');
        const endDate   = moment(e.target.value).endOf('month').format('YYYY-MM-DD');
        this.setState({
            currentMonth : e.target.value,
            startDate, 
            endDate
        },()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    handleChangeMode = (event, mode)=>{
            let updateObj = {
                viewMode : mode
            };
            this.setState(updateObj,()=>{
                this.loadOrders();
            });
    }

    handleChangeStoreMode = (event)=>{
        let updateObj = {
            storeMode : event.target.value
        };
        this.setState(updateObj,()=>{
            this.loadOrders();
        });
    }
    
    
    render(){
        const { 
            viewMode,
            recipeConfig,
            storeMode,
            overallDrinksCounter,
            currentMonth,
            startDate, 
            endDate,
            loading,
         } = this.state;
    

        return <div className='consumptionContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                <ToggleButtonGroup
                    style={{marginTop:20, marginBottom:10}}
                    color="primary"
                    disabled={loading}
                    value={viewMode}
                    exclusive
                    onChange={this.handleChangeMode}
                    >
                    <ToggleButton disabled={loading} value="day">Day</ToggleButton>
                    <ToggleButton disabled={loading} value="month">Month</ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl sx={{ my: '1em'}}>
                    <InputLabel>Branch</InputLabel>
                    <Select
                    color="primary"
                    value={storeMode}
                    label="Branch"
                    onChange={this.handleChangeStoreMode}
                    >
                    <MenuItem disabled={loading} value="all">Overview</MenuItem>
                    <MenuItem disabled={loading} value="Safdarjung">Safdarjung</MenuItem>
                    <MenuItem disabled={loading} value="GreaterKailash2">GK2</MenuItem>
                    <MenuItem disabled={loading} value="32ndAveGurgaon">32nd Ave</MenuItem>
                    <MenuItem disabled={loading} value="Galleria">Galleria</MenuItem>
                    <MenuItem disabled={loading} value="Events">Events</MenuItem>
                    </Select>
                </FormControl>
                { loading ? <Loader/> : <React.Fragment>
                    <div className='dateContainer'>
                    {viewMode === "day" ? <>                    
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    </>  : ''}
                    {viewMode === "month" ? <>
                    <input disabled={loading} type='month' className='dateInput' onChange={this.setMonth} value={currentMonth}/>
                    </> : ''}
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='1x'/>
                    </div>
                    </div>
                    </React.Fragment>}
            </div>
                <ConsumptionTable startDate={this.state.startDate} endDate={this.state.endDate} branch={this.state.storeMode} loading={loading} recipeConfig={recipeConfig} title={'Consumption Data'} showLargeAndSmall={true} data={overallDrinksCounter}/>
            </div>
            </div>
    } 
}

export default Reports;