import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import ConsumptionPredictionTable from '../../Common/ConsumptionPredictionTable/ConsumptionTable';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './ConsumptionReports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const Client = new Api();
const moment = require('moment-timezone');


const currentBranches = [
    { name: "Overview", value:"all"},
    { name: "Safdarjung", value:"Safdarjung"},
    { name: "Galleria", value:"Galleria"},
    { name: "GK1", value:"Got Tea - GK1"},
    { name: "GK2", value:"GreaterKailash2"},
    { name: "GCR", value:"Got Tea - Golf Course Road"},
    { name: "Punjabi Bagh", value:"Got Tea - Punjabi Bagh"},
    { name: "Nirvana", value:"Got Tea - Nirvana"},
    { name: "Vasant Kunj", value:"Got Tea - Vasant Kunj"}
];

class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            recipeConfig:[],
            loading:true,
            viewMode:'day',
            storeMode:'all',
            selectedDay:moment.tz(moment().add(1, 'day'), 'Asia/Kolkata').format('dddd'),
            browser : Utilities.getCurrentBrowser(),
            currentMonth:moment.tz(moment(), 'Asia/Kolkata').format('YYYY-MM'),
            goalKey:`all-${moment.tz(moment(), 'Asia/Kolkata').format('MM-YYYY')}`,
            startDate : moment.tz(moment().subtract(3, 'weeks'), 'Asia/Kolkata').format('YYYY-MM-DD'),
            endDate : moment.tz(moment().startOf('day'), 'Asia/Kolkata').format('YYYY-MM-DD'),
            cash : 0.0,
            cashCount : 0,
            revenueTotals : [],
            branchTotals:[],
            customerName : '',
            money : 0.0,
            data : null,
            stats : null,
            orders:null,
            addOnItems : [],
            orderItems : [],
            orderData : [],
            unknownOrderCount : 0
        }
        this.timer = null;
    }

    loadOrders = async ()=>{
        const {startDate, endDate, storeMode, } = this.state;
        let startDateString = moment.tz(startDate,'Asia/Kolkata').format('YYYY-MM-DD');
        let endDateString = moment.tz(endDate,'Asia/Kolkata').format('YYYY-MM-DD');
        this.setState({
            loading:true
        },()=>{
            Client.getGotTeaOrderDataDayWise(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf(),storeMode).then((orderData)=>{
                Client.getRecipeConfig().then((recipeData)=>{
                    this.setState({
                        recipeConfig:recipeData.ingredients,
                        loading:false,
                        orderData
                    },()=>{
                    });
                })
            }).catch((err)=>{
                console.log('GET GOT TEA DATA ERROR =>', err);
            });
        })
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    handleChangeDay = (event)=>{
        let updateObj = {
            selectedDay : event.target.value
        };
        this.setState(updateObj,()=>{
        });
    }
    

    handleChangeStoreMode = (event)=>{
        let updateObj = {
            storeMode : event.target.value
        };
        this.setState(updateObj,()=>{
            this.loadOrders();
        });
    }
    
    
    render(){
        const { 
            viewMode,
            recipeConfig,
            storeMode,
            currentMonth,
            startDate, 
            endDate,
            loading,
         } = this.state;
    

        return <div className='consumptionContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                <ToggleButtonGroup
                    style={{marginTop:20, marginBottom:10}}
                    color="primary"
                    disabled={loading}
                    value={viewMode}
                    exclusive
                    onChange={this.handleChangeMode}
                    >
                    <ToggleButton disabled={loading} value="day">Day</ToggleButton>
                    <ToggleButton disabled={loading} value="month">Month</ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl sx={{ my: '1em'}}>
                    <InputLabel>Branch</InputLabel>
                    <Select
                    color="primary"
                    value={storeMode}
                    label="Branch"
                    onChange={this.handleChangeStoreMode}
                    >
                    {currentBranches.map((b)=><MenuItem disabled={loading} value={b.value}>{b.name}</MenuItem>)}
                    </Select>
                    </FormControl>
                    <FormControl sx={{ my: '1em', width:200}}>
                    <InputLabel>Selected Day</InputLabel>
                    <Select
                    color="primary"
                    value={this.state.selectedDay}
                    label="Selected Day"
                    onChange={this.handleChangeDay}
                    >
                    <MenuItem disabled={loading} value="Monday">Monday</MenuItem>
                    <MenuItem disabled={loading} value="Tuesday">Tuesday</MenuItem>
                    <MenuItem disabled={loading} value="Wednesday">Wednesday</MenuItem>
                    <MenuItem disabled={loading} value="Thursday">Thursday</MenuItem>
                    <MenuItem disabled={loading} value="Friday">Friday</MenuItem>
                    <MenuItem disabled={loading} value="Saturday">Saturday</MenuItem>
                    <MenuItem disabled={loading} value="Sunday">Sunday</MenuItem>
                    </Select>
                    </FormControl>
            </div>
                {loading ? <div className='consumptionTable'><p className='loadingMessage'>Studying & analyzing last 3 Weeks Data ...</p><Loader/></div> : <ConsumptionPredictionTable startDate={this.state.startDate} endDate={this.state.endDate} branch={this.state.storeMode} loading={loading} recipeConfig={recipeConfig} title={`${this.state.selectedDay} Consumption Prediction`} data={this.state.orderData[this.state.selectedDay]}/>}
            </div>
            </div>
    } 
}

export default Reports;