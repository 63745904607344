import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import StatsTable from '../../Common/StatsTable/StatsTable';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './Reports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';

const Client = new Api();
const moment = require('moment');
class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            browser : Utilities.getCurrentBrowser(),
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD'),
            cash : 0.0,
            totalRevenue : 0.0,
            cashCount : 0,
            customerName : '',
            money : 0.0,
            data : null,
            stats : null,
            orderCount : 0,
            snackCount : 0,
            drinksCount : 0,
            orders:null,
            orderItems : [],
            phoneNum:props.match.params.phonenum
        }
        this.timer = null;
    }

    loadOrders = async ()=>{
        const {startDate, endDate } = this.state;
        let fbChat = document.querySelector('.fb_dialog');
        if(fbChat){ fbChat.remove();}
        let startDateString = moment(startDate).format('YYYY-MM-DD');
        let endDateString = moment(endDate).format('YYYY-MM-DD');
        this.setState({
            loading:true
        },()=>{
            Client.getShopifyOrderData(moment(startDateString).valueOf(), moment(endDateString).add(1,'days').valueOf()).then((data)=>{
                    let orderItems = data.items;
                    let orderCount = data.orderCount;
                    let totalRevenue = 0.0;
                    for(let item of orderItems){
                        totalRevenue += parseFloat(item.price);
                    }

                    this.setState({
                        orderCount,
                        totalRevenue,
                        orderItems,
                        loading:false
                    },()=>{
                        this.filterOrders();
                    });
            }).catch((err)=>{
                console.log('LOAD ORDERS ERROR =>', err);
            });
        })
    }

    filterOrders = () =>{
        const { orderItems} = this.state;
        let eventDrinksCounter = {};
        let eventSnacksCounter = {};
        let merchandiseCounter = {};

        let arr = [];
        let ecommerceRevenue = 0.0;
        let eventsRevenue = 0.0;
        let merchCount = 0;
        let drinksCount = 0;
        let snackCount = 0;
        for(const item of orderItems){
            let itemName = item["name"];
            if(itemName.toLowerCase().includes("croffle")){
                if(itemName.trim() === "Bubble Tea + Croffle Combo" || itemName.trim() === "1 Bubble Tea + Original Croffle"){
                    itemName = "1 Bubble Tea + 1 Croffle";
                }
                itemName = itemName.replace("(-10%)","").replace("Croffle - Original","Original Croffle").trim();
                if(itemName.trim() === "Corn and Cheese Croffle" || itemName.trim() === "Corn & Cheese Croffle"){
                    itemName = "Corn Cheese Croffle";
                }
            } else if(itemName.toLowerCase().includes("sandwich")){
                if(itemName.trim() === "Sandwich Veg"){
                    itemName = "Veg Sandwich";
                }
                if(itemName.trim() === "Sandwich Non Veg" || itemName.trim() === "Sandwich Chicken"){
                    itemName = "Non - Veg Sandwich";
                }
            } else {
                itemName = itemName.replace("[Pre-Order]", "").replace("Bubble Tea", "").replace("(-10%)","").replace("[No Bubbles]","").replace("No Bubbles","").replace("[New]","").trim();
            }
            if(Utilities.isSnack(itemName)){
                snackCount += parseInt(item["count"]);
                eventsRevenue += parseFloat(item["price"]);
                if(Utilities.isNullOrEmpty(eventSnacksCounter[itemName])){
                    eventSnacksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                eventSnacksCounter[itemName].count = eventSnacksCounter[itemName].count + parseInt(item["count"]);
            } 
            else if(Utilities.isMerch(itemName)){
                merchCount += parseInt(item["count"]);
                ecommerceRevenue += parseFloat(item["price"]);
                if(Utilities.isNullOrEmpty(merchandiseCounter[itemName])){
                    merchandiseCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                merchandiseCounter[itemName].count = merchandiseCounter[itemName].count + parseInt(item["count"]);
            } else {
                drinksCount += parseInt(item["count"]);
                eventsRevenue += parseFloat(item["price"]);
                if(Utilities.isNullOrEmpty(eventDrinksCounter[itemName])){
                    eventDrinksCounter[itemName] = { count : 0, dineIn : 0, takeaway : 0, delivery : 0};
                }
                eventDrinksCounter[itemName].count = eventDrinksCounter[itemName].count + parseInt(item["count"]);
            }
        }

        let customerName = '';
        if(arr.length > 0){ customerName = arr[0].userName;}
        this.setState({ 
            data : arr,
            eventDrinksCounter,
            eventSnacksCounter,
            merchandiseCounter,
            customerName, 
            snackCount, 
            drinksCount, 
            merchCount,
            eventsRevenue,
            ecommerceRevenue
    });
    }
    
    getOrders = (newStatus)=>{
        this.loadOrders();
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
            this.filterOrders();
            // this.loadOrders();
        });
    }

    render(){
        const { 
            orderCount,
            eventDrinksCounter,
            eventSnacksCounter,
            merchandiseCounter,
            cash,
            startDate, 
            endDate,
            totalRevenue,
            ecommerceRevenue,
            eventsRevenue,
            loading
         } = this.state;

        return <div className='shopifyReportContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <div className='infoDiv'>
                { loading ? <Loader/> : <React.Fragment>
                    <p className='orderCount'>{orderCount} Orders</p>
                    <p className='orderCount'>Average Order Value : ₹{Utilities.formatIndianCurrency(parseFloat(totalRevenue) / orderCount)}</p>
                    {(!Utilities.isNullOrEmpty(cash)) ? <p className='orderCount money'>Cash Collected : ₹{Utilities.formatIndianCurrency(cash)}</p> : ''}
                    <p className='moneyLarge'>₹{Utilities.formatIndianCurrency(totalRevenue)}</p>
                    <div className="revenueSplits">
                    <div>
                        <p className='sourceTitle'>E-Commerce</p>
                        <p className={`money`}>₹{Utilities.formatIndianCurrency(parseFloat(ecommerceRevenue))}</p>
                    </div>
                    <div>
                        <p className='sourceTitle'>Outside Events</p>
                        <p className={`money`}>₹{Utilities.formatIndianCurrency(parseFloat(eventsRevenue))}</p>
                    </div>
                    </div>
                    <div className='dateContainer'>
                    <input  disabled={loading} type='date' className='dateInput' onChange={this.setStartDate} value={startDate}/>
                    <input  disabled={loading} type='date' className='dateInput' onChange={this.setEndDate} value={endDate}/>
                    <div className='updateBtn' onClick={this.onUpdateClick}> 
                    <FontAwesomeIcon className='btnIcon' icon={faArrowCircleRight} size='1x'/>
                    </div>
                    </div>
                    </React.Fragment>}
            </div>
                <StatsTable skipFlavourBreakdown={true} loading={loading} title={'E-Commerce Goods Sold'} data={merchandiseCounter}/>          
                <StatsTable skipFlavourBreakdown={true} loading={loading} title={'Drinks Sold'} data={eventDrinksCounter}/>
                <StatsTable skipFlavourBreakdown={true} loading={loading} title={'Snacks Sold'} data={eventSnacksCounter}/> 
            </div>
            </div>

    } 
}

export default Reports;