import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './HourwiseSales.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import { Bar } from 'react-chartjs-2';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

const Client = new Api();
const moment = require('moment');
class HourwiseSales extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            browser : Utilities.getCurrentBrowser(),
            targetDate : moment().format('YYYY-MM-DD'),
            startDate : moment().format('YYYY-MM-DD'),
            endDate : moment().format('YYYY-MM-DD'),
            cash : 0.0,
            cashCount : 0,
            revenueTotals : [],
            customerName : '',
            money : 0.0,
            data : null,
            stats : null,
            orderCount : 0,
            itemCount : 0,
            snackCount : 0,
            drinksCount : 0,
            orders:null,
            orderItems : [],
            unknownOrderCount : 0,
            phoneNum:props.match.params.phonenum,
            orderCountData:{},
            revenueData : {},
            itemCountData : {}
        }
        this.timer = null;
    }

    loadOrders = async ()=>{
        let fbChat = document.querySelector('.fb_dialog');
        if(fbChat){ fbChat.remove();}
        let {targetDate} = this.state;
        this.setState({
            loading:true
        },()=>{
            Client.getHourwiseSalesSummary(moment(targetDate).subtract(30,'days').valueOf(), moment(targetDate).valueOf()).then((monthSales)=>{
                let monthTracker = {};
                for(let item of monthSales){
                    monthTracker[item['Hour']] = item;
                }
                // Client.getHourwiseSalesSummary(1636914600100, 1637000999999).then((daySales)=>{
                // Client.getHourwiseSalesSummary(moment(yesterday).valueOf(), moment(yesterday).endOf('day').valueOf()).then((daySales)=>{
                    Client.getHourwiseSalesSummary(moment(targetDate).valueOf(), moment(targetDate).endOf('day').valueOf()).then((daySales)=>{
                    let dayTracker = {};
                    for(let item of daySales){
                        dayTracker[item['Hour']] = item;
                    }
                    let orderCountData = {};
                    const labels = [
                        '11-12','12-13','13-14','14-15','15-16','16-17','17-18','18-19','19-20','20-21','21-22'
                    ];
                    const actualLabels = [
                        '11AM-12PM','12PM-1PM','1PM-2PM','2PM-3PM','3PM-4PM','4PM-5PM','5PM-6PM','6PM-7PM','7PM-8PM','8PM-9PM','9PM-10PM'
                    ];
                    orderCountData.labels = actualLabels;
                    orderCountData.datasets = [
                        {
                            label: '30 Days Average',
                            data: labels.map((hour)=>{
                                if(monthTracker[hour]){
                                    return (monthTracker[hour]['Order Count'] / 30).toFixed(1);
                                } else {
                                    return 0;
                                }
                            }),
                            backgroundColor: '#91BCB9'
                        },
                        {
                            label: 'Today',
                            data: labels.map((hour)=>{
                                if(dayTracker[hour]){
                                    return dayTracker[hour]['Order Count'];
                                } else {
                                    return 0;
                                }
                            }),
                            backgroundColor: '#47908A'
                        }
                    ];

                    let revenueData = {};
                    revenueData.labels = actualLabels;
                    revenueData.datasets = [
                        {
                            label: '30 Days Average',
                            data: labels.map((hour)=>{
                                if(monthTracker[hour]){
                                    return (monthTracker[hour]['Revenue'] / 30.0).toFixed(1);
                                } else {
                                    return 0;
                                }
                            }),
                            backgroundColor: '#91BCB9'
                        },
                        {
                            label: 'Today',
                            data: labels.map((hour)=>{
                                if(dayTracker[hour]){
                                    return dayTracker[hour]['Revenue'];
                                } else {
                                    return 0;
                                }
                            }),
                            backgroundColor: '#47908A'
                        }
                    ];
                    this.setState({
                        orderCountData,
                        revenueData
                    });
                }).catch((err)=>{
                    console.log('LOAD ORDERS ERROR =>', err);
                });
            }).catch((err)=>{
                console.log('LOAD ORDERS ERROR =>', err);
            });

            Client.getHourwiseItemsSummary(moment(targetDate).subtract(30,'days').valueOf(), moment(targetDate).valueOf()).then((monthSales)=>{
                let monthTracker = {};
                for(let item of monthSales){
                    monthTracker[item['Hour']] = item;
                }
                Client.getHourwiseItemsSummary(moment(targetDate).valueOf(), moment(targetDate).endOf('day').valueOf()).then((daySales)=>{
                    let dayTracker = {};
                    for(let item of daySales){
                        dayTracker[item['Hour']] = item;
                    }
                    let itemCountData = {};
                    const labels = [
                        '11-12','12-13','13-14','14-15','15-16','16-17','17-18','18-19','19-20','20-21','21-22'
                    ];
                    const actualLabels = [
                        '11AM-12PM','12PM-1PM','1PM-2PM','2PM-3PM','3PM-4PM','4PM-5PM','5PM-6PM','6PM-7PM','7PM-8PM','8PM-9PM','9PM-10PM'
                    ];
                    itemCountData.labels = actualLabels;
                    itemCountData.datasets = [
                        {
                            label: '30 Days Average',
                            data: labels.map((hour)=>{
                                if(monthTracker[hour]){
                                    return (monthTracker[hour]['Item Count'] / 30).toFixed(1);
                                } else {
                                    return 0;
                                }
                            }),
                            backgroundColor: '#91BCB9'
                        },
                        {
                            label: 'Today',
                            data: labels.map((hour)=>{
                                if(dayTracker[hour]){
                                    return dayTracker[hour]['Item Count'];
                                } else {
                                    return 0;
                                }
                            }),
                            backgroundColor: '#47908A'
                        }
                    ];
                    this.setState({
                        itemCountData
                    });
                }).catch((err)=>{
                    console.log('LOAD ORDERS ERROR =>', err);
                });
            }).catch((err)=>{
                console.log('LOAD ORDERS ERROR =>', err);
            });

            
        })
    }
   
    getOrders = (newStatus)=>{
        this.loadOrders();
    }

    componentDidMount(){
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadOrders();
            } else {
                window.location.href = '/login';
            }
        });
    }

    componentWillUnmount(){
        clearInterval(this.timer);
    }

    setTargetDate = (e) => {
        this.setState({targetDate : e.target.value},()=>{
            this.loadOrders();
        });
    }

    render(){
        const { 
            revenueTotals,
            targetDate,
            orderCountData,
            revenueData,
            itemCountData,
            loading
         } = this.state;

        return <div className='hourwiseContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
            <div className='dateDiv'>
                <div>
                <p>Target Date</p>
                <input type='date' className='dateInput' onChange={this.setTargetDate} value={targetDate}/>   
                </div>
               </div>
                <div className='infoDiv'>
                <p>Order Count</p>
                {Utilities.isNullOrEmpty(orderCountData) ? <Loader/> : 
                <Bar data={orderCountData} options={options} /> }
                </div>
                <div className='infoDiv'>
                <p>Item Count</p>
                {Utilities.isNullOrEmpty(itemCountData) ? <Loader/> : 
                <Bar data={itemCountData} options={options} /> }
                </div>
                <div className='infoDiv'>
                <p>Revenue</p>
                {Utilities.isNullOrEmpty(revenueData) ? <Loader/> : 
                <Bar data={revenueData} options={options} /> }
                </div>
            </div>
            </div>
    } 
}

export default HourwiseSales;