import React from 'react';
import Chip from '@mui/material/Chip';

const StatusTag = ({ status, fontSize }) => {
  let color;

  switch (status) {
    case 'Order Placed':
      color = 'primary';
      break;
    case 'Acknowledged':
      color = 'success';
      break;
    case 'Dispatched':
      color = 'warning';
      break;
    case 'Delivered':
      color = 'error';
      break;
    case 'Delivered Partially':
      color = 'warning';
      break;
    default:
      color = 'default';
      break;
  }

  return (
    <Chip label={status} color={color} sx={{padding:1, fontSize:fontSize || 14, maxWidth:'200px'}}/>
  );
};

export default StatusTag;